import { createSlice } from '@reduxjs/toolkit';
import { dispatch } from '../store';
// utils
import axios from '../../utils/axios';
import { Position } from '../../@types/position';
import { ListMeta, ListQueryParams } from '../../@types/table';

// ----------------------------------------------------------------------

type PositionResponse = {
  data: any;
  type: string;
};

type PositionState = {
  isLoading: boolean;
  error: any;
  position: null | Position;
  response: null | PositionResponse;
  positionList: Position[];
  positionListMeta: ListMeta;
};

const initialState: PositionState = {
  isLoading: false,
  error: false,
  position: null,
  response: null,
  positionList: [],
  positionListMeta: {} as ListMeta
};

const slice = createSlice({
  name: 'position',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // START LOADING
    reset(state) {
      state.isLoading = false;
      state.error = false;
      state.response = null;
      state.position = null;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET POSITION
    getPositionSuccess(state, action) {
      state.isLoading = false;
      state.position = action.payload.data;
      state.response = {
        data: action.payload.success,
        type: 'GET_POSITION_SUCCESS'
      };
    },

    // CREATE POSITION
    createPositionSuccess(state, action) {
      state.isLoading = false;
      state.position = action.payload.data;
      state.response = {
        data: action.payload.data,
        type: 'CREATE_POSITION_SUCCESS'
      };
    },

    // UPDATE POSITION
    updatePositionSuccess(state, action) {
      state.isLoading = false;
      state.position = action.payload.data;
      state.response = {
        data: action.payload.data,
        type: 'UPDATE_POSITION_SUCCESS'
      };
    },

    // DELETE POSITION
    deletePositionSuccess(state, action) {
      state.isLoading = false;
      state.position = action.payload;
      state.response = {
        data: action.payload.data,
        type: 'DELETE_POSITION_SUCCESS'
      };
    },

    // GET MANAGE POSITION
    getPositionListSuccess(state, action) {
      state.isLoading = false;
      state.positionList = action.payload.data;
      state.positionListMeta = action.payload.meta as ListMeta;
      state.response = {
        data: action.payload.data,
        type: 'GET_POSITION_LIST_SUCCESS'
      };
    },

    // GET ALL POSITION
    getAllPositionListSuccess(state, action) {
      state.isLoading = false;
      state.positionList = action.payload.data;
      state.response = {
        data: action.payload.data,
        type: 'GET_POSITION_LIST_SUCCESS'
      };
    }
  }
});

// Reducer
export default slice.reducer;

// Actions
// ----------------------------------------------------------------------

export function getPositionById(positionId: string) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`position/get/${positionId}`);
      dispatch(slice.actions.getPositionSuccess(response.data));
    } catch (error: any) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function createPosition(payload: any) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/position/create', payload);
      dispatch(slice.actions.createPositionSuccess(response.data));
    } catch (error: any) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function updatePosition(payload: any) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/position/update', payload);
      dispatch(slice.actions.updatePositionSuccess(response.data));
    } catch (error: any) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getPositionList(params: ListQueryParams) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/position/get/paginate', {
        params: {
          'pagination[pageSize]': params.pageSize,
          'pagination[page]': params.page
        }
      });
      dispatch(slice.actions.getPositionListSuccess(response.data));
    } catch (error: any) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getAllPositionList() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/position/get/all');
      dispatch(slice.actions.getAllPositionListSuccess(response.data));
    } catch (error: any) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getAllPositionListHavingProvider() {
  return async () => {
    dispatch(slice.actions.startLoading());
    const response = await axios.get('/position/get/having-provider');
    return response;
  };
}

export function getAgencyAllPositionListHavingProvider(agencyId: string) {
  return async () => {
    dispatch(slice.actions.startLoading());
    const response = await axios.get(`/agency/${agencyId}/position/get/having-provider`);
    return response;
  };
}

export function deletePositionById(positionId: string) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/position/delete', { positionId });
      dispatch(slice.actions.deletePositionSuccess(response.data));
    } catch (error: any) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// for guest
export function resetState() {
  return async () => {
    dispatch(slice.actions.reset());
  };
}
