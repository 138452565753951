import { createSlice } from '@reduxjs/toolkit';
import { dispatch } from '../store';
// utils
import axios from '../../utils/axios';
import { News } from '../../@types/news';
import { ListMeta, ListQueryParams } from '../../@types/table';

// ----------------------------------------------------------------------

type NewsResponse = {
  data: any;
  type: string;
};

type NewsState = {
  isLoading: boolean;
  error: any;
  news: null | News;
  response: null | NewsResponse;
  newsList: News[];
  newsListMeta: ListMeta;
};

const initialState: NewsState = {
  isLoading: false,
  error: false,
  news: null,
  response: null,
  newsList: [],
  newsListMeta: {} as ListMeta
};

const slice = createSlice({
  name: 'news',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // START LOADING
    reset(state) {
      state.isLoading = false;
      state.error = false;
      state.response = null;
      state.news = null;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET NEWS
    getNewsSuccess(state, action) {
      state.isLoading = false;
      state.news = action.payload.data;
      state.response = {
        data: action.payload.success,
        type: 'GET_NEWS_SUCCESS'
      };
    },

    // CREATE NEWS
    createNewsSuccess(state, action) {
      state.isLoading = false;
      state.news = action.payload.data;
      state.response = {
        data: action.payload.data,
        type: 'CREATE_NEWS_SUCCESS'
      };
    },

    // UPDATE NEWS
    updateNewsSuccess(state, action) {
      state.isLoading = false;
      state.news = action.payload.data;
      state.response = {
        data: action.payload.data,
        type: 'UPDATE_NEWS_SUCCESS'
      };
    },

    // DELETE NEWS
    deleteNewsSuccess(state, action) {
      state.isLoading = false;
      state.news = action.payload;
      state.response = {
        data: action.payload.data,
        type: 'DELETE_NEWS_SUCCESS'
      };
    },

    // GET MANAGE NEWS
    getNewsListSuccess(state, action) {
      state.isLoading = false;
      state.newsList = action.payload.data;
      state.newsListMeta = action.payload.meta as ListMeta;
      state.response = {
        data: action.payload.data,
        type: 'GET_NEWS_LIST_SUCCESS'
      };
    },

    // GET LAST NEWS
    getLastNewsSuccess(state, action) {
      state.isLoading = false;
      state.response = {
        data: action.payload.data,
        type: 'GET_LAST_NEWS_SUCCESS'
      };
    }
  }
});

// Reducer
export default slice.reducer;

// Actions
// ----------------------------------------------------------------------

export function getNewsById(newsId: string) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`news/get/${newsId}`);
      dispatch(slice.actions.getNewsSuccess(response.data));
    } catch (error: any) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function createNews(payload: any) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/news/create', payload, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
      dispatch(slice.actions.createNewsSuccess(response.data));
    } catch (error: any) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function updateNews(payload: any) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/news/update', payload);
      dispatch(slice.actions.updateNewsSuccess(response.data));
    } catch (error: any) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getNewsList(params: ListQueryParams) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/news/get/all', {
        params: {
          'pagination[pageSize]': params.pageSize,
          'pagination[page]': params.page
        }
      });
      dispatch(slice.actions.getNewsListSuccess(response.data));
    } catch (error: any) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getAllNewsList() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/provider/news/all');
      dispatch(slice.actions.getNewsListSuccess(response.data));
    } catch (error: any) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function deleteNewsById(newsId: string) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/news/delete', { newsId });
      dispatch(slice.actions.deleteNewsSuccess(response.data));
    } catch (error: any) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// for guest
export function resetState() {
  return async () => {
    dispatch(slice.actions.reset());
  };
}

export function getNewsByIdForGuest(newsId: string) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`provider/news/get/${newsId}`);
      dispatch(slice.actions.getNewsSuccess(response.data));
    } catch (error: any) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getNewsBySlug(newsSlug: string) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`news/${newsSlug}`);
      dispatch(slice.actions.getNewsSuccess(response.data));
    } catch (error: any) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getLastNewsForGuest() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`provider/news/last`);
      dispatch(slice.actions.getLastNewsSuccess(response.data));
    } catch (error: any) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
