import { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useSnackbar } from 'notistack';
// material
import { alpha, styled } from '@mui/material/styles';
import {
  Box,
  AppBar,
  Toolbar,
  Typography,
  Menu,
  MenuItem,
  ListItemIcon,
  Divider
} from '@mui/material';
import { Icon } from '@iconify/react';
import logOutOutline from '@iconify/icons-eva/log-out-outline';
import menuOutline from '@iconify/icons-eva/menu-outline';
// hooks
import useCollapseDrawer from '../../hooks/useCollapseDrawer';
import useGuestProviderAuth from '../../hooks/useGuestProviderAuth';
// components
import { MIconButton } from '../../components/@material-extend';
// ----------------------------------------------------------------------

const COLLAPSE_WIDTH = 102;

const APPBAR_MOBILE = 56;
const APPBAR_DESKTOP = 56;

const RootStyle = styled(AppBar)(({ theme }) => ({
  boxShadow: 'none',
  backdropFilter: 'blur(6px)',
  WebkitBackdropFilter: 'blur(6px)', // Fix on Mobile
  backgroundColor: alpha(theme.palette.background.default, 0.72)
}));

const ToolbarStyle = styled(Toolbar)(({ theme }) => ({
  minHeight: APPBAR_MOBILE,
  borderBottom: '1px solid #EAEAEA',
  backgroundColor: '#fff',
  [theme.breakpoints.up('lg')]: {
    minHeight: APPBAR_DESKTOP,
    padding: theme.spacing(0, 5)
  }
}));

// ----------------------------------------------------------------------

export default function ProviderNavbar() {
  let navigate = useNavigate();
  const location = useLocation();
  const { enqueueSnackbar } = useSnackbar();
  const { isCollapse } = useCollapseDrawer();
  const { provider, logout } = useGuestProviderAuth();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = async () => {
    try {
      await logout?.();
      navigate('/');
    } catch (error: any) {
      console.error(error);
      enqueueSnackbar('Unable to logout', { variant: 'error' });
    }
  };

  return (
    <>
      <RootStyle
        sx={{
          ...(isCollapse && {
            width: { lg: `calc(100% - ${COLLAPSE_WIDTH}px)` }
          })
        }}
      >
        <ToolbarStyle>
          <Box
            sx={[
              {
                px: 2.5,
                my: 1.5,
                mr: 13
              },
              (theme) => ({
                [theme.breakpoints.between('sm', 'md')]: {
                  mr: 7
                },
                [theme.breakpoints.down('sm')]: {
                  mr: 'auto'
                }
              })
            ]}
          >
            <Typography
              variant="body2"
              sx={{ color: 'text.secondary', textTransform: 'uppercase', fontSize: '14px' }}
              noWrap
            >
              {provider?.User?.agencyName}
            </Typography>
          </Box>

          <Box
            sx={[
              { flexGrow: 1 },
              (theme) => ({
                [theme.breakpoints.down('sm')]: {
                  display: 'none'
                }
              })
            ]}
          >
            {provider?.User?.newsModule === true && (
              <Box
                sx={[
                  {
                    cursor: 'pointer',
                    display: 'inline-block',
                    padding: '16px 0 12px 0',
                    mx: '20px',
                    borderBottomWidth: '4px',
                    borderBottomStyle: 'solid',
                    borderBottomColor:
                      location.pathname === '/provider/h/news' ? '#438FFE' : 'transparent'
                  },
                  (theme) => ({
                    [theme.breakpoints.down('sm')]: {
                      display: 'none'
                    }
                  })
                ]}
                onClick={() => {
                  navigate(`/provider/h/news`);
                }}
              >
                <Typography
                  variant="body2"
                  sx={{ color: 'text.secondary', textTransform: 'uppercase', fontSize: '14px' }}
                  noWrap
                >
                  NEWS
                </Typography>
              </Box>
            )}

            {provider?.User?.billingModule === true && (
              <Box
                sx={{
                  cursor: 'pointer',
                  display: 'inline-block',
                  padding: '16px 0 12px 0',
                  mx: '20px',
                  borderBottomWidth: '4px',
                  borderBottomStyle: 'solid',
                  borderBottomColor:
                    location.pathname === '/provider/h/billing' ? '#438FFE' : 'transparent'
                }}
                onClick={() => {
                  navigate(`/provider/h/billing`);
                }}
              >
                <Typography
                  variant="body2"
                  sx={{ color: 'text.secondary', textTransform: 'uppercase', fontSize: '14px' }}
                  noWrap
                >
                  BILLING
                </Typography>
              </Box>
            )}

            {provider?.User?.clearanceModule === true && (
              <Box
                sx={{
                  cursor: 'pointer',
                  display: 'inline-block',
                  padding: '16px 0 12px 0',
                  mx: '20px',
                  borderBottomWidth: '4px',
                  borderBottomStyle: 'solid',
                  borderBottomColor:
                    location.pathname === '/provider/h/clearances' ? '#438FFE' : 'transparent'
                }}
                onClick={() => {
                  navigate(`/provider/h/clearances`);
                }}
              >
                <Typography
                  variant="body2"
                  sx={{ color: 'text.secondary', textTransform: 'uppercase', fontSize: '14px' }}
                  noWrap
                >
                  CLEARANCES
                </Typography>
              </Box>
            )}

            {provider?.User?.zipCodeModule === true && (
              <Box
                sx={{
                  cursor: 'pointer',
                  display: 'inline-block',
                  padding: '16px 0 12px 0',
                  mx: '20px',
                  borderBottomWidth: '4px',
                  borderBottomStyle: 'solid',
                  borderBottomColor:
                    location.pathname === '/provider/h/my-zip-codes' ? '#438FFE' : 'transparent'
                }}
                onClick={() => {
                  navigate(`/provider/h/my-zip-codes`);
                }}
              >
                <Typography
                  variant="body2"
                  sx={{ color: 'text.secondary', textTransform: 'uppercase', fontSize: '14px' }}
                  noWrap
                >
                  MY ZIP CODES
                </Typography>
              </Box>
            )}

            {provider?.isCoAdmin === true && (
              <Box
                sx={{
                  cursor: 'pointer',
                  display: 'inline-block',
                  padding: '16px 0 12px 0',
                  mx: '20px',
                  borderBottomWidth: '4px',
                  borderBottomStyle: 'solid',
                  borderBottomColor:
                    location.pathname === '/provider/h/email' ? '#438FFE' : 'transparent'
                }}
                onClick={() => {
                  navigate(`/provider/h/email`);
                }}
              >
                <Typography
                  variant="body2"
                  sx={{ color: 'text.secondary', textTransform: 'uppercase', fontSize: '14px' }}
                  noWrap
                >
                  EMAIL
                </Typography>
              </Box>
            )}
          </Box>

          <Box
            sx={[
              { padding: '4px 0 5px 0', cursor: 'pointer', display: 'flex' },
              (theme) => ({
                [theme.breakpoints.down('sm')]: {
                  display: 'none'
                }
              })
            ]}
            onClick={handleLogout}
          >
            <Typography
              variant="body2"
              sx={{ color: 'text.secondary', marginTop: '9px', marginRight: '9px' }}
              noWrap
            >
              Logout
            </Typography>
            <MIconButton>
              <Icon icon={logOutOutline} />
            </MIconButton>
          </Box>

          <Box
            sx={[
              { padding: '4px 0 5px 0', cursor: 'pointer', display: 'none' },
              (theme) => ({
                [theme.breakpoints.down('sm')]: {
                  display: 'block'
                }
              })
            ]}
            onClick={handleMenuOpen}
          >
            <MIconButton>
              <Icon icon={menuOutline} />
            </MIconButton>
          </Box>
          <Menu
            anchorEl={anchorEl}
            id="account-menu"
            open={open}
            onClose={handleMenuClose}
            onClick={handleMenuClose}
            PaperProps={{
              elevation: 0,
              sx: {
                overflow: 'visible',
                filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                mt: 1.5,
                '& .MuiAvatar-root': {
                  width: 32,
                  height: 32,
                  ml: -0.5,
                  mr: 1
                },
                '&:before': {
                  content: '""',
                  display: 'block',
                  position: 'absolute',
                  top: 0,
                  right: 14,
                  width: 10,
                  height: 10,
                  bgcolor: 'background.paper',
                  transform: 'translateY(-50%) rotate(45deg)',
                  zIndex: 0
                }
              }
            }}
            transformOrigin={{ horizontal: 'right', vertical: 'top' }}
            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
          >
            <div>
              {provider?.User?.newsModule === true && (
                <MenuItem
                  onClick={() => {
                    navigate('/provider/h/news');
                  }}
                >
                  NEWS
                </MenuItem>
              )}
              {provider?.User?.billingModule === true && (
                <MenuItem
                  onClick={() => {
                    navigate('/provider/h/billing');
                  }}
                >
                  BILLING
                </MenuItem>
              )}
              {provider?.User?.clearanceModule === true && (
                <MenuItem
                  onClick={() => {
                    navigate('/provider/h/clearances');
                  }}
                >
                  CLEARANCES
                </MenuItem>
              )}
              {provider?.User?.zipCodeModule === true && (
                <MenuItem
                  onClick={() => {
                    navigate('/provider/h/my-zip-codes');
                  }}
                >
                  MY ZIP CODES
                </MenuItem>
              )}
              {provider?.isCoAdmin === true && (
                <MenuItem
                  onClick={() => {
                    navigate('/provider/h/email');
                  }}
                >
                  EMAIL
                </MenuItem>
              )}
            </div>
            <Divider />
            <MenuItem onClick={handleLogout}>
              <ListItemIcon sx={{ mr: 0 }}>
                <Icon icon={logOutOutline} />
              </ListItemIcon>
              Logout
            </MenuItem>
          </Menu>
        </ToolbarStyle>
      </RootStyle>
    </>
  );
}
