import { createSlice } from '@reduxjs/toolkit';
import { dispatch } from '../store';
// utils
import axios from '../../utils/axios';
import { AgencyBilling } from '../../@types/agency-billing';
import { ListMeta } from '../../@types/table';
// ----------------------------------------------------------------------

type AgencyBillingResponse = {
  data: any;
  type: string;
};

type AgencyBillingState = {
  isLoading: boolean;
  error: any;
  agencyBilling: null | any;
  response: null | AgencyBillingResponse;
  agencyBillingList: AgencyBilling[];
  agencyBillingListMeta: ListMeta;
};

const initialState: AgencyBillingState = {
  isLoading: false,
  error: false,
  agencyBilling: null,
  response: null,
  agencyBillingList: [],
  agencyBillingListMeta: {} as ListMeta
};

const slice = createSlice({
  name: 'agencyBilling',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // START LOADING
    reset(state) {
      state.isLoading = false;
      state.error = false;
      state.response = null;
      state.agencyBilling = null;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // CREATE AGENCY BILLING
    createAgencyBillingSuccess(state, action) {
      state.isLoading = false;
      state.agencyBilling = action.payload.data;
      state.response = {
        data: action.payload.data,
        type: 'CREATE_AGENCY_BILLING_SUCCESS'
      };
    },

    // UNDO AGENCY BILLING
    undoAgencyBillingSuccess(state, action) {
      state.isLoading = false;
      state.agencyBilling = action.payload.data;
      state.response = {
        data: action.payload.data,
        type: 'UNDO_AGENCY_BILLING_SUCCESS'
      };
    },

    // GET AGENCY BILLING LIST
    getAgencyBillingSuccess(state, action) {
      state.isLoading = false;
      state.agencyBillingList = action.payload.data;
      state.agencyBillingListMeta = action.payload.meta as ListMeta;
      state.response = {
        data: action.payload.success,
        type: 'GET_AGENCY_BILLING_LIST_SUCCESS'
      };
    }
  }
});

// Reducer
export default slice.reducer;

// Actions
// ----------------------------------------------------------------------
export function getBillingBetweenDate(params: any) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/agency-billing/get`, { params });
      dispatch(slice.actions.getAgencyBillingSuccess(response.data));
    } catch (error: any) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function submitBilling(payload: any) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/agency-billing/submit', payload);
      dispatch(slice.actions.createAgencyBillingSuccess(response.data));
    } catch (error: any) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function undoSubmitBilling(payload: any) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/agency-billing/submit/undo', payload);
      dispatch(slice.actions.undoAgencyBillingSuccess(response.data));
    } catch (error: any) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getMonthBilling(year: number, month: number) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/provider/agency-billing/get/${year}/${month}`);
      dispatch(slice.actions.getAgencyBillingSuccess(response.data));
    } catch (error: any) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function resetState() {
  return async () => {
    dispatch(slice.actions.reset());
  };
}
