import * as Yup from 'yup';
import { useSnackbar } from 'notistack';
import { useState, useEffect } from 'react';
import { Icon } from '@iconify/react';
import closeFill from '@iconify/icons-eva/close-fill';
import eyeFill from '@iconify/icons-eva/eye-fill';
import eyeOffFill from '@iconify/icons-eva/eye-off-fill';
// material
import {
  Box,
  Backdrop,
  Paper,
  Typography,
  Stack,
  TextField,
  Alert,
  InputAdornment,
  IconButton
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
// hooks
import useAuth from '../../../hooks/useAuth';
import useIsMountedRef from '../../../hooks/useIsMountedRef';
//
import Scrollbar from '../../Scrollbar';
import { MIconButton } from '../../@material-extend';
import { Form, FormikProvider, useFormik } from 'formik';
// ----------------------------------------------------------------------

const DRAWER_WIDTH = 360;

type InitialValues = {
  old_password: string;
  new_password: string;
  confirm_password: string;
  afterSubmit?: string;
};

type ChangePasswordFormProps = {
  openForm: boolean;
  formClosed: VoidFunction;
};

export default function ChangePasswordForm({ openForm, formClosed }: ChangePasswordFormProps) {
  const { changePassword } = useAuth();
  const isMountedRef = useIsMountedRef();
  const [open, setOpen] = useState(false);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [showOldPassword, setShowOldPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);

  // useEffect(() => {
  //   if (open) {
  //     document.body.style.overflow = 'hidden';
  //   } else {
  //     document.body.style.overflow = 'unset';
  //   }
  // }, [open]);

  useEffect(() => {
    setOpen(openForm);
  }, [openForm]);

  const handleClose = () => {
    setOpen(false);
    formik.resetForm();
    formClosed();
  };

  const ChangePasswordSchema = Yup.object().shape({
    old_password: Yup.string().required('Old Password is required'),
    new_password: Yup.string().required('New Password is required'),
    confirm_password: Yup.string()
      .required('Confirm Password is required')
      .oneOf([Yup.ref('new_password'), null], 'Passwords must match')
  });

  const formik = useFormik<InitialValues>({
    initialValues: {
      old_password: '',
      new_password: '',
      confirm_password: ''
    },
    validationSchema: ChangePasswordSchema,
    onSubmit: async (values, { setSubmitting, resetForm, setErrors }) => {
      try {
        await changePassword(values);
        enqueueSnackbar('Password changed success', {
          variant: 'success',
          action: (key) => (
            <MIconButton size="small" onClick={() => closeSnackbar(key)}>
              <Icon icon={closeFill} />
            </MIconButton>
          )
        });
        if (isMountedRef.current) {
          setSubmitting(false);
        }
        handleClose();
      } catch (error: any) {
        resetForm();
        if (isMountedRef.current) {
          setSubmitting(false);
          setErrors({ afterSubmit: error.message });
        }
      }
    }
  });

  const { errors, touched, handleSubmit, isSubmitting, getFieldProps } = formik;

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Backdrop
          sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={open}
          onClick={handleClose}
        />

        <Box
          sx={[
            {
              top: 0,
              bottom: 0,
              right: 0,
              position: 'fixed',
              zIndex: 2001,
              ...(open && { right: 0 })
            },
            (theme) => ({
              [theme.breakpoints.down('sm')]: {
                top: '70px'
              }
            })
          ]}
        >
          <Paper
            sx={{
              height: 1,
              width: '0px',
              borderRadius: 0,
              overflow: 'hidden',
              boxShadow: (theme) => theme.customShadows.z24,
              transition: (theme) => theme.transitions.create('width'),
              ...(open && { width: DRAWER_WIDTH })
            }}
          >
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              sx={{ py: 2, pr: 1, pl: 2.5 }}
            >
              <Typography variant="subtitle1">Change Password</Typography>
              <MIconButton onClick={handleClose}>
                <Icon icon={closeFill} width={20} height={20} />
              </MIconButton>
            </Stack>

            <Scrollbar sx={{ height: 1 }}>
              <Stack spacing={4} sx={{ pt: 3, px: 3, pb: 15 }}>
                <Stack spacing={1.5}>
                  {errors.afterSubmit && <Alert severity="error">{errors.afterSubmit}</Alert>}

                  <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 3, sm: 2 }}>
                    <TextField
                      fullWidth
                      label="Old Password"
                      variant="filled"
                      type={showOldPassword ? 'text' : 'password'}
                      autoComplete="current-password"
                      {...getFieldProps('old_password')}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              onClick={() => {
                                setShowOldPassword((show) => !show);
                              }}
                              edge="end"
                            >
                              <Icon icon={showOldPassword ? eyeFill : eyeOffFill} />
                            </IconButton>
                          </InputAdornment>
                        )
                      }}
                      error={Boolean(touched.old_password && errors.old_password)}
                      helperText={touched.old_password && errors.old_password}
                    />
                  </Stack>

                  <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 3, sm: 2 }}>
                    <TextField
                      fullWidth
                      label="New Password"
                      variant="filled"
                      type={showNewPassword ? 'text' : 'password'}
                      autoComplete="current-password"
                      {...getFieldProps('new_password')}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              onClick={() => {
                                setShowNewPassword((show) => !show);
                              }}
                              edge="end"
                            >
                              <Icon icon={showNewPassword ? eyeFill : eyeOffFill} />
                            </IconButton>
                          </InputAdornment>
                        )
                      }}
                      error={Boolean(touched.new_password && errors.new_password)}
                      helperText={touched.new_password && errors.new_password}
                    />
                  </Stack>

                  <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 3, sm: 2 }}>
                    <TextField
                      fullWidth
                      label="Confirm Password"
                      variant="filled"
                      type="password"
                      autoComplete="current-password"
                      {...getFieldProps('confirm_password')}
                      error={Boolean(touched.confirm_password && errors.confirm_password)}
                      helperText={touched.confirm_password && errors.confirm_password}
                    />
                  </Stack>
                </Stack>

                <Stack spacing={1.5}>
                  <LoadingButton
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                    loading={isSubmitting}
                  >
                    Update
                  </LoadingButton>
                </Stack>
              </Stack>
            </Scrollbar>
          </Paper>
        </Box>
      </Form>
    </FormikProvider>
  );
}
