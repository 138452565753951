import { createSlice } from '@reduxjs/toolkit';
import { dispatch } from '../store';
// utils
import axios from '../../utils/axios';
import { NewsUser } from '../../@types/school';
import { ListMeta, ListQueryParams } from '../../@types/table';

// ----------------------------------------------------------------------
type NewsUserResponse = {
  data: any;
  type: string;
};

type NewsUserState = {
  isLoading: boolean;
  error: any;
  newsUser: null | NewsUser;
  response: null | NewsUserResponse;
  newsUserList: NewsUser[];
  newsUserListMeta: ListMeta;
  newsUserListPaginatedList: NewsUser[];
  newsUserListPaginatedListMeta: ListMeta;
};

const initialState: NewsUserState = {
  isLoading: false,
  error: false,
  newsUser: null,
  response: null,
  newsUserList: [],
  newsUserListMeta: {} as ListMeta,
  newsUserListPaginatedList: [],
  newsUserListPaginatedListMeta: {} as ListMeta
};

const slice = createSlice({
  name: 'newsuser',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // START LOADING
    reset(state) {
      state.isLoading = false;
      state.error = false;
      state.response = null;
      state.newsUser = null;
    },

    // START LOADING
    clearError(state) {
      state.error = false;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    getListSuccess(state, action) {
      state.isLoading = false;
      state.newsUserList = action.payload.data;
      state.newsUserListMeta = action.payload.meta as ListMeta;
      state.response = {
        data: action.payload.data,
        type: 'GET_NEWS_USER_LIST_SUCCESS'
      };
    }
  }
});

// Reducer
export default slice.reducer;

// Actions
// ----------------------------------------------------------------------

export function getAllNewsUserList(newsId: string) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/get/director/newsuser/${newsId}`);
      dispatch(slice.actions.getListSuccess(response.data));
      return response;
    } catch (error: any) {
      dispatch(slice.actions.hasError(error));
      return null;
    }
  };
}
