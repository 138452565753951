import * as Yup from 'yup';
import { useSnackbar } from 'notistack';
import { useState, useEffect } from 'react';
import { Icon } from '@iconify/react';
import closeFill from '@iconify/icons-eva/close-fill';
import {
  Box,
  Backdrop,
  Paper,
  Typography,
  Stack,
  TextField,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  FormHelperText
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import Scrollbar from '../../Scrollbar';
import { MIconButton } from '../../@material-extend';
import { Form, FormikProvider, useFormik } from 'formik';
import { RootState, useDispatch, useSelector } from '../../../redux/store';
import { getGradeById, updateGrade } from '../../../redux/slices/grade';
import { getAllStudentListForTeacher } from '../../../redux/slices/student';
import { getAllHomeworkList } from '../../../redux/slices/homework';
import { getGradePoinData } from 'redux/slices/student';
const DRAWER_WIDTH = 360;

type EditGradeFormProps = {
  editId: number;
  openForm: boolean;
  formClosed: VoidFunction;
  processedSuccess: VoidFunction;
};

export default function EditGradeForm({
  editId,
  openForm,
  formClosed,
  processedSuccess
}: EditGradeFormProps) {
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const { grade, response: gradeResponse } = useSelector((state: RootState) => state.grade);
  const { studentList } = useSelector((state: RootState) => state.student);
  const { homeworkList } = useSelector((state: RootState) => state.homework);
  const [editData, setEditData] = useState(grade);

  const handleStudentChange = (event: any) => {
    setFieldValue('selectedStudent', event.target.value);
  };

  useEffect(() => {
    setEditData(grade);
  }, [grade]);

  useEffect(() => {
    setOpen(openForm);
    fetchData();
  }, [openForm]);
  // useEffect(() => {
  //   dispatch(getGradePoinData());
  // });
  const fetchData = async () => {
    try {
      await dispatch(getAllStudentListForTeacher());
      await dispatch(getAllHomeworkList(editId));
      await dispatch(getGradeById(editId));
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  useEffect(() => {
    if (gradeResponse && gradeResponse.type === 'UPDATE_GRADE_SUCCESS') {
      enqueueSnackbar('Update success', {
        variant: 'success',
        action: (key) => (
          <MIconButton size="small" onClick={() => closeSnackbar(key)}>
            <Icon icon={closeFill} />
          </MIconButton>
        )
      });
      processedSuccess();
      handleClose();
    }
  }, [gradeResponse, enqueueSnackbar]);

  const handleClose = () => {
    setOpen(false);
    formik.resetForm();
    formClosed();
  };

  const UpdateGradeSchema = Yup.object().shape({
    selectedHomework: Yup.string().required('Homework must be selected'),
    selectedStudent: Yup.string().required('Student must be selected'),
    point: Yup.string()
      .matches(/^[0-9Aa]*$/, 'Point can only contain numbers, A, or a')
      .required('Point is required')
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      selectedHomework: editData?.homework_id ?? '',
      selectedStudent: editData?.student_id ?? '',
      point: editData?.point ?? ''
    },
    validationSchema: UpdateGradeSchema,
    onSubmit: async (values, { setSubmitting, resetForm, setErrors }) => {
      try {
        let formData = new FormData();
        formData.append('homework_id', values.selectedHomework);
        formData.append('student_id', values.selectedStudent);
        formData.append('point', values.point);
        await dispatch(updateGrade(editId, formData));
        await dispatch(getGradePoinData(editId));
      } catch (error) {
        console.error(error);
        setSubmitting(false);
        setErrors(error as any);
      }
    }
  });

  const { errors, touched, handleSubmit, isSubmitting, getFieldProps, setFieldValue, values } =
    formik;

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Backdrop
          sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={open}
          onClick={handleClose}
        />
        <Box
          sx={[
            {
              top: 0,
              bottom: 0,
              right: 0,
              position: 'fixed',
              zIndex: 2001,
              ...(open && { right: 0 })
            },
            (theme) => ({
              [theme.breakpoints.down('sm')]: {
                top: '70px'
              }
            })
          ]}
        >
          <Paper
            sx={{
              height: 1,
              width: '0px',
              overflow: 'hidden',
              borderRadius: 0,
              boxShadow: (theme) => theme.customShadows.z24,
              transition: (theme) => theme.transitions.create('width'),
              ...(open && { width: DRAWER_WIDTH })
            }}
          >
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              sx={{ py: 2, pr: 1, pl: 2.5 }}
            >
              <Typography variant="subtitle1">Edit Grade</Typography>
              <MIconButton onClick={handleClose}>
                <Icon icon={closeFill} width={20} height={20} />
              </MIconButton>
            </Stack>

            <Scrollbar sx={{ height: 1 }}>
              <Stack spacing={4} sx={{ pt: 3, px: 3, pb: 15 }}>
                <Stack spacing={1.5}>
                  <FormControl
                    fullWidth
                    variant="filled"
                    error={Boolean(touched.selectedStudent && errors.selectedStudent)}
                  >
                    <InputLabel>Select Student</InputLabel>
                    <Select
                      labelId="select-student-label"
                      id="select-student"
                      value={values.selectedStudent}
                      onChange={handleStudentChange}
                      MenuProps={{ style: { zIndex: 2002 } }}
                    >
                      {studentList.map((student) => (
                        <MenuItem key={student.id} value={student.id}>
                          {student.fullName}
                        </MenuItem>
                      ))}
                    </Select>
                    {touched.selectedStudent && errors.selectedStudent && (
                      <FormHelperText>{errors.selectedStudent}</FormHelperText>
                    )}
                  </FormControl>

                  <FormControl
                    fullWidth
                    variant="filled"
                    error={Boolean(touched.selectedHomework && errors.selectedHomework)}
                  >
                    <InputLabel>Select Homework</InputLabel>
                    <Select
                      labelId="select-homework-label"
                      id="select-homework"
                      value={values.selectedHomework}
                      onChange={(e) => setFieldValue('selectedHomework', e.target.value)}
                      MenuProps={{ style: { zIndex: 2002 } }}
                    >
                      {homeworkList.map((homework) => (
                        <MenuItem key={homework.id} value={homework.id}>
                          {homework.title}
                        </MenuItem>
                      ))}
                    </Select>
                    {touched.selectedHomework && errors.selectedHomework && (
                      <FormHelperText>{errors.selectedHomework}</FormHelperText>
                    )}
                  </FormControl>
                </Stack>

                <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 3, sm: 2 }}>
                  <TextField
                    fullWidth
                    label="Point"
                    variant="filled"
                    type="text"
                    {...getFieldProps('point')}
                    error={Boolean(touched.point && errors.point)}
                    helperText={touched.point && errors.point}
                  />
                </Stack>

                <Stack spacing={1.5}>
                  <LoadingButton
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                    loading={isSubmitting}
                    disabled={isSubmitting}
                  >
                    Add Grade
                  </LoadingButton>
                </Stack>
              </Stack>
            </Scrollbar>
          </Paper>
        </Box>
      </Form>
    </FormikProvider>
  );
}
