import { ReactNode } from 'react';
import { Navigate } from 'react-router-dom';
// hooks
import useProviderAuth from '../hooks/useGuestProviderAuth';
import useAuth from '../hooks/useAuth';
// routes
import { PATH_DASHBOARD, PATH_GUEST } from '../routes/paths';

// ----------------------------------------------------------------------

type ProviderGuestProps = {
  children: ReactNode;
};

export default function ProviderGuestGuard({ children }: ProviderGuestProps) {
  const { isAuthenticated, provider } = useProviderAuth();
  const { isAuthenticated: isUserAuthenticated, user } = useAuth();
  console.log(user);
  console.log('Provi', provider);
  if (isUserAuthenticated && user) {
    return <Navigate to={PATH_DASHBOARD.provider.root} />;
  }
  if (isAuthenticated) {
    if (provider?.User && !provider.User?.clearanceModule) {
      if (!provider.User?.billingModule) {
        return <Navigate to={PATH_GUEST.newsList} />;
      } else {
        return <Navigate to={PATH_GUEST.billingHome} />;
      }
    } else {
      if (provider?.User?.billingModule) {
        return <Navigate to={PATH_GUEST.home} />;
      } else {
        return <Navigate to={PATH_GUEST.clearanceList} />;
      }
    }
  }

  return <>{children}</>;
}
