import { createContext, ReactNode, useEffect, useReducer } from 'react';
import { useParams } from 'react-router-dom';
// utils
import axios from '../utils/axios';
import { isValidToken, setSession } from '../utils/jwt';
// @types
import {
  ActionMap,
  GuestProviderState,
  AuthUser,
  GuestProviderContextType
} from '../@types/authentication';
import useAuth from 'hooks/useAuth';
import { aC } from '@fullcalendar/core/internal-common';

// ----------------------------------------------------------------------

enum Types {
  Initial = 'INITIALIZE',
  Login = 'LOGIN',
  Logout = 'LOGOUT',
  ChangePassword = 'CHANGE_PASSWORD',
  UpdateProvider = 'UPDATE_PROVIDER'
}

type JWTAuthPayload = {
  [Types.Initial]: {
    isAuthenticated: boolean;
    provider: AuthUser;
    // agency:AuthUser
  };
  [Types.Login]: {
    agency: AuthUser;
    provider: AuthUser;
  };
  [Types.Logout]: undefined;
  [Types.ChangePassword]: undefined;
  [Types.UpdateProvider]: {
    provider: AuthUser;
  };
};

export type JWTActions = ActionMap<JWTAuthPayload>[keyof ActionMap<JWTAuthPayload>];

const initialState: GuestProviderState = {
  isAuthenticated: false,
  isInitialized: false,
  provider: null
};

const JWTReducer = (state: GuestProviderState, action: JWTActions) => {
  switch (action.type) {
    case 'INITIALIZE':
      return {
        isAuthenticated: action.payload.isAuthenticated,
        isInitialized: true,
        provider: action.payload.provider
      };
    case 'LOGIN':
      return {
        ...state,
        isAuthenticated: true,
        provider: action.payload.provider
      };
    case 'LOGOUT':
      return {
        ...state,
        isAuthenticated: false,
        provider: null
      };
    case 'UPDATE_PROVIDER':
      return {
        ...state,
        isAuthenticated: true,
        provider: action.payload.provider
      };

    default:
      return state;
  }
};

const GuestProviderContext = createContext<GuestProviderContextType | null>(null);

function GuestProvider({ children }: { children: ReactNode }) {
  const [state, dispatch] = useReducer(JWTReducer, initialState);
  const { loginAgency } = useAuth();

  useEffect(() => {
    const initialize = async () => {
      try {
        const accessToken = window.localStorage.getItem('accessToken');

        if (accessToken && isValidToken(accessToken)) {
          const response = await axios.get('/provider/me');
          const { provider } = response.data;

          dispatch({
            type: Types.Initial,
            payload: {
              isAuthenticated: true,
              provider
            }
          });
        } else {
          dispatch({
            type: Types.Initial,
            payload: {
              isAuthenticated: false,
              provider: null
            }
          });
        }
      } catch (err) {
        console.error(err);
        dispatch({
          type: Types.Initial,
          payload: {
            isAuthenticated: false,
            provider: null
          }
        });
      }
    };

    initialize();
  }, []);

  // const login = async (email: string, password: string) => {
  //   setSession(null);
  //   const response = await axios.post(`/provider/login`, {
  //     email,
  //     password
  //   });
  //   const { accessToken, provider } = response.data;

  //   setSession(accessToken);
  //   dispatch({
  //     type: Types.Login,
  //     payload: {
  //       provider

  //     }
  //   });
  // };

  const login = async (email: string, password: string) => {
    setSession(null);
    const response = await axios.post(`/provider/login`, {
      email,
      password
    });

    const { accessToken, provider, user } = response.data;
    console.log('Token', accessToken);

    // const users = provider; // Rename provider to user
    if (user) {
      loginAgency(accessToken, user);
      console.log('User', user);
    } else {
      setSession(accessToken);

      dispatch({
        type: Types.Login,
        payload: {
          agency: user,
          provider
        }
      });
    }
  };

  const verify = async (qrCode: string, personalCode: string) => {
    setSession(null);
    const response = await axios.post(`/provider/verify`, {
      qrCode,
      personalCode
    });
    const { accessToken, provider, agency } = response.data;

    setSession(accessToken);
    dispatch({
      type: Types.Login,
      payload: {
        agency,
        provider
      }
    });
  };

  const changePassword = async (payload: any) => {
    await axios.post(`/provider/change-password`, payload);

    dispatch({
      type: Types.ChangePassword
    });
  };

  const logout = async () => {
    setSession(null);
    dispatch({ type: Types.Logout });
  };

  const updateProvider = async (provider: any) => {
    dispatch({
      type: Types.UpdateProvider,
      payload: {
        provider
      }
    });
  };

  return (
    <GuestProviderContext.Provider
      value={{
        ...state,
        method: 'jwt',
        login,
        verify,
        logout,
        changePassword,
        updateProvider
      }}
    >
      {children}
    </GuestProviderContext.Provider>
  );
}

// function GuestProvider({ children }: { children: ReactNode }) {
//   const [state, dispatch] = useReducer(JWTReducer, initialState);

//   useEffect(() => {
//     const initialize = async () => {
//       try {
//         const accessToken = window.localStorage.getItem("accessToken");

//         if (accessToken && isValidToken(accessToken)) {
//           const response = await axios.get("/provider/me");
//           const { provider, agency } = response.data; // Support both entities

//           dispatch({
//             type: Types.Initial,
//             payload: {
//               isAuthenticated: true,
//               provider,
//               agency, // Add agency to the payload
//             },
//           });
//         } else {
//           dispatch({
//             type: Types.Initial,
//             payload: {
//               isAuthenticated: false,
//               provider: null,
//               agency: null,
//             },
//           });
//         }
//       } catch (err) {
//         console.error(err);
//         dispatch({
//           type: Types.Initial,
//           payload: {
//             isAuthenticated: false,
//             provider: null,
//             agency: null,
//           },
//         });
//       }
//     };

//     initialize();
//   }, []);
// }

export { GuestProviderContext, GuestProvider };
