import { ReactNode } from 'react';
import { Container, Alert, AlertTitle } from '@mui/material';
// hooks
import useAuth from '../hooks/useAuth';
// ----------------------------------------------------------------------

type ModuleBasedAuthGuardProp = {
  moduleName: string;
  children: ReactNode | string;
};

export default function ModuleBasedAuthGuard({ moduleName, children }: ModuleBasedAuthGuardProp) {
  const { user } = useAuth();

  if (!user || user[moduleName] !== true) {
    return (
      <Container>
        <Alert severity="error">
          <AlertTitle>Permission Denied</AlertTitle>
          You do not have permission to access this page
        </Alert>
      </Container>
    );
  }

  return <>{children}</>;
}
