import { combineReducers } from 'redux';
import storage from 'redux-persist/lib/storage';
// slices
import agencyReducer from './slices/agency';
import providerReducer from './slices/provider';
import clearanceReducer from './slices/clearance';
import teacherClearanceReducer from './slices/teacher-clearance';
import teacherPersonalCodeReducer from './slices/teacher-personal-code';
import personalCodeReducer from './slices/personal-code';
import newsReducer from './slices/news';
import serviceStatusReducer from './slices/service-status';
import sessionReducer from './slices/session';
import billingReducer from './slices/billing';
import holidayReducer from './slices/holiday';
import agencyBillingReducer from './slices/agency-billing';
import bonusReducer from './slices/bonus';
import positionReducer from './slices/position';
import positionRateReducer from './slices/provider-rate';
import scheduledSessionReducer from './slices/scheduled-session';
import providerZipcodePriorityReducer from './slices/provider-zipcode-priority';
import emailReducer from './slices/email';
import classReducer from './slices/class';
import directorReducer from './slices/director';
import teacherReducer from './slices/teacher';
import studentReducer from './slices/student';
import teacherNewsReducer from './slices/teacher-news';
import directorNewsReducer from './slices/director-news';
import newsUserReducer from './slices/newsuser';
import homeworkReducer from './slices/homework';
import teacherRateReducer from './slices/teacher-rate';
import teacherBillingReducer from './slices/teacher-billing';
import teacherSessionReducer from './slices/teacher-session';
import directorBillingReducer from './slices/director-billing';
import directorHolidayReducer from './slices/director-holiday';
import gradeReducer from './slices/grade';
import directorClassGradingReducer from './slices/director-class-grading';
import directorDashboard from './slices/director-dashboard';

// ----------------------------------------------------------------------

const rootPersistConfig = {
  key: 'root',
  storage,
  keyPrefix: 'redux-',
  whitelist: []
};

const rootReducer = combineReducers({
  agency: agencyReducer,

  provider: providerReducer,
  clearance: clearanceReducer,
  teacherClearance: teacherClearanceReducer,
  teacherPersonalCode: teacherPersonalCodeReducer,
  personalCode: personalCodeReducer,
  news: newsReducer,
  serviceStatus: serviceStatusReducer,
  session: sessionReducer,
  billing: billingReducer,
  holiday: holidayReducer,
  agencyBilling: agencyBillingReducer,
  bonus: bonusReducer,
  position: positionReducer,
  providerRate: positionRateReducer,
  scheduledSession: scheduledSessionReducer,
  providerZipcodePriority: providerZipcodePriorityReducer,
  email: emailReducer,
  class: classReducer,
  director: directorReducer,
  directorDashboard: directorDashboard,
  teacher: teacherReducer,
  student: studentReducer,
  teacherNews: teacherNewsReducer,
  directorNews: directorNewsReducer,
  newsUser: newsUserReducer,
  homework: homeworkReducer,
  teacherRate: teacherRateReducer,
  teacherBilling: teacherBillingReducer,
  teacherSession: teacherSessionReducer,
  directorBilling: directorBillingReducer,
  directorHoliday: directorHolidayReducer,
  grade: gradeReducer,
  directorClassGrading: directorClassGradingReducer
});

export { rootPersistConfig, rootReducer };
