import { createSlice } from '@reduxjs/toolkit';
import { dispatch } from '../store';
// utils
import axios from '../../utils/axios';
import { Clearance } from '../../@types/provider';
import { ListMeta, ListQueryParams } from '../../@types/table';

// ----------------------------------------------------------------------

type ClearanceResponse = {
  data: any;
  type: string;
};

type ClearanceState = {
  isLoading: boolean;
  error: any;
  clearance: null | Clearance;
  response: null | ClearanceResponse;
  clearanceList: Clearance[];
  clearanceListMeta: ListMeta;
};

const initialState: ClearanceState = {
  isLoading: false,
  error: false,
  clearance: null,
  response: null,
  clearanceList: [],
  clearanceListMeta: {} as ListMeta
};

const slice = createSlice({
  name: 'clearance',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // START LOADING
    reset(state) {
      state.isLoading = false;
      state.error = false;
      state.response = null;
      state.clearance = null;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET CLEARANCE
    getClearanceSuccess(state, action) {
      state.isLoading = false;
      state.clearance = action.payload.data;
      state.response = {
        data: action.payload.success,
        type: 'GET_CLEARANCE_SUCCESS'
      };
    },

    // CREATE CLEARANCE
    createClearanceSuccess(state, action) {
      state.isLoading = false;
      state.clearance = action.payload.data;
      state.response = {
        data: action.payload.data,
        type: 'CREATE_CLEARANCE_SUCCESS'
      };
    },

    // UPDATE CLEARANCE
    updateClearanceSuccess(state, action) {
      state.isLoading = false;
      state.clearance = action.payload.data;
      state.response = {
        data: action.payload.data,
        type: 'UPDATE_CLEARANCE_SUCCESS'
      };
    },

    // DELETE CLEARANCE
    deleteClearanceSuccess(state, action) {
      state.isLoading = false;
      state.clearance = action.payload;
      state.response = {
        data: action.payload.data,
        type: 'DELETE_CLEARANCE_SUCCESS'
      };
    },

    // GET MANAGE CLEARANCES
    getClearanceListSuccess(state, action) {
      state.isLoading = false;
      state.clearanceList = action.payload.data;
      state.clearanceListMeta = action.payload.meta as ListMeta;
      state.response = {
        data: action.payload.data,
        type: 'GET_CLEARANCE_LIST_SUCCESS'
      };
    },

    // GET MANAGE CLEARANCES
    getClearanceListWithDownloadSuccess(state, action) {
      state.isLoading = false;
      state.response = {
        data: action.payload.data,
        type: 'GET_CLEARANCE_LIST_DOWNLOAD_SUCCESS'
      };
    }
  }
});

// Reducer
export default slice.reducer;

// Actions
// ----------------------------------------------------------------------

export function getClearanceById(clearanceId: string) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`clearance/get/${clearanceId}`);
      dispatch(slice.actions.getClearanceSuccess(response.data));
    } catch (error: any) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function createClearance(payload: any) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/clearance/create', payload, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
      dispatch(slice.actions.createClearanceSuccess(response.data));
    } catch (error: any) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function updateClearance(payload: any) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/clearance/update', payload);
      dispatch(slice.actions.updateClearanceSuccess(response.data));
    } catch (error: any) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getClearanceList(params: ListQueryParams) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/clearance/get/all', {
        params: {
          'pagination[pageSize]': params.pageSize,
          'pagination[page]': params.page
        }
      });
      dispatch(slice.actions.getClearanceListSuccess(response.data));
    } catch (error: any) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function deleteClearanceById(clearanceId: string) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/clearance/delete', { clearanceId: clearanceId });
      dispatch(slice.actions.deleteClearanceSuccess(response.data));
    } catch (error: any) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// for provider
export function getClearanceListByProvider() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/provider/get/clearances/all');
      dispatch(slice.actions.getClearanceListWithDownloadSuccess(response.data));
    } catch (error: any) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// for guest
export function resetState() {
  return async () => {
    dispatch(slice.actions.reset());
  };
}
