import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
// material
import { styled } from '@mui/material/styles';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import {
  Box,
  Tab,
  Stack,
  Container,
  Typography,
  InputLabel,
  Select,
  MenuItem,
  FormControl
} from '@mui/material';
import { SelectChangeEvent } from '@mui/material/Select';
// components
import Page from '../../components/Page';
import { LoginForm } from '../../components/authentication/login';
import { ProviderLoginForm } from '../../components/authentication/login';
//
import { Block } from '../components-overview/Block';
import ProviderGuestGuard from '../../guards/ProviderGuestGuard';
import { GuestProvider } from 'contexts/GuestProviderContext';
// constants
// ----------------------------------------------------------------------

const RootStyle = styled(Page)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex'
  },
  background: '#293643'
}));

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  display: 'flex',
  minHeight: '100vh',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: theme.spacing(12, 0)
}));

const style = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexWrap: 'wrap',
  borderRadius: 0,
  padding: 0
} as const;

// ----------------------------------------------------------------------

export default function Login() {
  const navigate = useNavigate();
  const [value, setValue] = useState('1');
  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  return (
    <RootStyle
      title="Login | You Improve"
      sx={{ background: value === '1' ? '#293643' : '#00255A' }}
    >
      <Container maxWidth="sm">
        <ContentStyle>
          <img
            src={`/static/brand/logo_full.png`}
            alt="Logo"
            useMap="#logomap"
            style={{ width: 300, marginLeft: 'auto', marginRight: 'auto', marginBottom: '3rem' }}
          />

          <map name="logomap">
            <area
              shape="circle"
              coords="218,12,12"
              alt="Cup of coffee"
              onClick={() => {
                navigate('/admin/login');
              }}
              style={{ cursor: 'pointer' }}
            />
          </map>
          <Block sx={style}>
            <TabContext value={value}>
              <TabList
                onChange={handleChange}
                style={{
                  width: '100%',
                  borderBottom: '1px solid #EAEAEA'
                }}
                centered
              >
                <Tab
                  key="1"
                  label="PROVIDER"
                  value="1"
                  sx={{ minHeight: '56px', paddingLeft: '2rem', paddingRight: '2rem' }}
                />
                <Tab
                  key="2"
                  label="SCHOOL"
                  value="2"
                  sx={{ minHeight: '56px', paddingLeft: '2rem', paddingRight: '2rem' }}
                />
              </TabList>
              <Box sx={{ flexGrow: 1 }}>
                <TabPanel key="1" value="1">
                  <Stack
                    direction="row"
                    alignItems="center"
                    sx={{ mt: 4, mb: 4, justifyContent: 'center' }}
                  ></Stack>
                  <GuestProvider>
                    <ProviderGuestGuard>
                      <ProviderLoginForm />
                    </ProviderGuestGuard>
                  </GuestProvider>
                </TabPanel>
                <TabPanel key="2" value="2">
                  <Stack
                    direction="row"
                    alignItems="center"
                    sx={{ mt: 4, mb: 4, justifyContent: 'center' }}
                  ></Stack>
                  <LoginForm />
                </TabPanel>
              </Box>
            </TabContext>
          </Block>
        </ContentStyle>
      </Container>
    </RootStyle>
  );
}
