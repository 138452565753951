import { createSlice } from '@reduxjs/toolkit';
import { dispatch } from '../store';
// utils
import axios from '../../utils/axios';
import { Holiday } from '../../@types/holiday';
import { ListMeta } from '../../@types/table';

// ----------------------------------------------------------------------

type HolidayResponse = {
  data: any;
  type: string;
};

type HolidayState = {
  isLoading: boolean;
  error: any;
  holiday: null | Holiday;
  response: null | HolidayResponse;
  holidayList: Holiday[];
  holidayListMeta: ListMeta;
};

const initialState: HolidayState = {
  isLoading: false,
  error: false,
  holiday: null,
  response: null,
  holidayList: [],
  holidayListMeta: {} as ListMeta
};

const slice = createSlice({
  name: 'holiday',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // START LOADING
    reset(state) {
      state.isLoading = false;
      state.error = false;
      state.response = null;
      state.holiday = null;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET HOLIDAY
    getHolidaySuccess(state, action) {
      state.isLoading = false;
      state.holiday = action.payload.data;
      state.response = {
        data: action.payload.success,
        type: 'GET_HOLIDAY_SUCCESS'
      };
    },

    // CREATE HOLIDAY
    createHolidaySuccess(state, action) {
      state.isLoading = false;
      state.holiday = action.payload.data;
      state.response = {
        data: action.payload.data,
        type: 'CREATE_HOLIDAY_SUCCESS'
      };
    },

    // UPDATE HOLIDAY
    updateHolidaySuccess(state, action) {
      state.isLoading = false;
      state.holiday = action.payload.data;
      state.response = {
        data: action.payload.data,
        type: 'UPDATE_HOLIDAY_SUCCESS'
      };
    },

    // DELETE HOLIDAY
    deleteHolidaySuccess(state, action) {
      state.isLoading = false;
      state.response = {
        data: action.payload.data,
        type: 'DELETE_HOLIDAY_SUCCESS'
      };
    },

    // GET MANAGE HOLIDAY
    getHolidayListSuccess(state, action) {
      state.isLoading = false;
      state.holidayList = action.payload.data;
      state.holidayListMeta = action.payload.meta as ListMeta;
      state.response = {
        data: action.payload.data,
        type: 'GET_HOLIDAY_LIST_SUCCESS'
      };
    }
  }
});

// Reducer
export default slice.reducer;

// Actions
// ----------------------------------------------------------------------

export function getHolidayById(holidayId: string) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`holiday/get/${holidayId}`);
      dispatch(slice.actions.getHolidaySuccess(response.data));
    } catch (error: any) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function createHoliday(payload: any) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/holiday/create', payload);
      dispatch(slice.actions.createHolidaySuccess(response.data));
    } catch (error: any) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function updateHoliday(payload: any) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/holiday/update', payload);
      dispatch(slice.actions.updateHolidaySuccess(response.data));
    } catch (error: any) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getHolidayList() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/holiday/get/all');
      dispatch(slice.actions.getHolidayListSuccess(response.data));
    } catch (error: any) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function deleteHolidayById(holidayId: string) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/holiday/delete', { holidayId });
      dispatch(slice.actions.deleteHolidaySuccess(response.data));
    } catch (error: any) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// for guest
export function getHolidayListByMonthForGuest(month: number) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/provider/holiday/month/${month}/get`);
      dispatch(slice.actions.getHolidayListSuccess(response.data));
    } catch (error: any) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// for guest
export function resetState() {
  return async () => {
    dispatch(slice.actions.reset());
  };
}
