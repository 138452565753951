import { createSlice } from '@reduxjs/toolkit';
import { dispatch } from '../store';
// utils
import axios from '../../utils/axios';
import { Class } from '../../@types/school';
import { ListMeta, ListQueryParams } from '../../@types/table';

// ----------------------------------------------------------------------

type ClassResponse = {
  data: any;
  type: string;
};

type ClassState = {
  isLoading: boolean;
  error: any;
  class: null | Class;
  response: null | ClassResponse;
  classList: Class[];
  classListMeta: ListMeta;
};

const initialState: ClassState = {
  isLoading: false,
  error: false,
  class: null,
  response: null,
  classList: [],
  classListMeta: {} as ListMeta
};

const slice = createSlice({
  name: 'class',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // START LOADING
    reset(state) {
      state.isLoading = false;
      state.error = false;
      state.response = null;
      state.class = null;
    },

    // START LOADING
    clearError(state) {
      state.error = false;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;

      state.error = action.payload;
    },

    deleteSuccess(state, action) {
      state.isLoading = false;
      state.class = action.payload;
      state.response = {
        data: action.payload.data,
        type: 'DELETE_CLASS_SUCCESS'
      };
    },

    updateSuccess(state, action) {
      state.isLoading = false;
      state.class = action.payload.data;
      state.response = {
        data: action.payload.data,
        type: 'UPDATE_CLASS_SUCCESS'
      };
    },

    createSuccess(state, action) {
      state.isLoading = false;
      state.class = action.payload.data;
      state.response = {
        data: action.payload.data,
        type: 'CREATE_CLASS_SUCCESS'
      };
    },

    getListSuccess(state, action) {
      state.isLoading = false;
      state.classList = action.payload.data;
      state.classListMeta = action.payload.meta as ListMeta;
      state.response = {
        data: action.payload.data,
        type: 'GET_CLASS_LIST_SUCCESS'
      };
    },

    getAllListSuccess(state, action) {
      state.isLoading = false;
      state.classList = action.payload.data;
      state.response = {
        data: action.payload.data,
        type: 'GET_CLASS_LIST_SUCCESS'
      };
    },

    getSuccess(state, action) {
      state.isLoading = false;
      state.class = action.payload.data;
      state.response = {
        data: action.payload.data,
        type: 'GET_CLASS_SUCCESS'
      };
    }
  }
});

// Reducer
export default slice.reducer;

// Actions
// ----------------------------------------------------------------------

export function getClassByID(recordId: number) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      let classID = recordId;
      if (!classID) {
        classID = 0;
      }
      const response = await axios.get(`/class/${classID}`);
      dispatch(slice.actions.getSuccess(response.data));
    } catch (error: any) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getClassList(params: ListQueryParams) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/classes/paginate', {
        params: {
          'pagination[pageSize]': params.pageSize,
          'pagination[page]': params.page
        }
      });
      dispatch(slice.actions.getListSuccess(response.data));
      return response;
    } catch (error: any) {
      dispatch(slice.actions.hasError(error));
      return null;
    }
  };
}

export function deleteClass(recordId: number) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.delete(`/class/${recordId}`);
      dispatch(slice.actions.deleteSuccess(response.data));
    } catch (error: any) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function updateClass(recordId: number, payload: any) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.put(`/class/${recordId}`, payload);
      dispatch(slice.actions.updateSuccess(response.data));
    } catch (error: any) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function createClass(payload: any) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(`/class/`, payload);
      dispatch(slice.actions.createSuccess(response.data));
    } catch (error: any) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getAllClassList() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/classes/all');
      dispatch(slice.actions.getAllListSuccess(response.data));
    } catch (error: any) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function resetState() {
  return async () => {
    dispatch(slice.actions.reset());
  };
}

// for guest
export function clearError() {
  return async () => {
    dispatch(slice.actions.clearError());
  };
}
