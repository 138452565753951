import { ReactNode } from 'react';
import { Container, Alert, AlertTitle } from '@mui/material';
// hooks
import useGuestProviderAuth from 'hooks/useGuestProviderAuth';
// ----------------------------------------------------------------------

type ModuleBasedGuestGuardProp = {
  moduleName: string;
  children: ReactNode | string;
};

export default function ModuleBasedGuestGuard({ moduleName, children }: ModuleBasedGuestGuardProp) {
  const { provider } = useGuestProviderAuth();

  if (!provider || !provider?.User || provider?.User[moduleName] !== true) {
    return (
      <Container sx={{ mt: 4 }}>
        <Alert severity="error">
          <AlertTitle>Permission Denied</AlertTitle>
          You do not have permission to access this page
        </Alert>
      </Container>
    );
  }

  return <>{children}</>;
}
