import { createSlice } from '@reduxjs/toolkit';
import { dispatch } from '../store';
// utils
import axios from '../../utils/axios';
import { TeacherSession } from '../../@types/teacher-session';
import { ListMeta } from '../../@types/table';

// ----------------------------------------------------------------------

type TeacherSessionResponse = {
  data: any;
  type: string;
};

type TeacherSessionState = {
  isLoading: boolean;
  error: any;
  teacherSession: null | TeacherSession;
  response: null | TeacherSessionResponse;
  teacherSessionList: TeacherSession[];
  teacherSessionListMeta: ListMeta;
};

const initialState: TeacherSessionState = {
  isLoading: false,
  error: false,
  teacherSession: null,
  response: null,
  teacherSessionList: [],
  teacherSessionListMeta: {} as ListMeta
};

const slice = createSlice({
  name: 'teacherSession',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // START LOADING
    reset(state) {
      state.isLoading = false;
      state.error = false;
      state.response = null;
      state.teacherSession = null;
      state.teacherSessionList = [];
    },

    clearResponse(state) {
      state.response = null;
    },

    clearError(state) {
      state.error = false;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // CREATE SESSION
    createTeacherSessionSuccess(state, action) {
      state.isLoading = false;
      state.teacherSession = action.payload.data;
      state.response = {
        data: action.payload.data,
        type: 'CREATE_TEACHER_SESSION_SUCCESS'
      };
    },

    // UPDATE SESSION
    updateTeacherSessionSuccess(state, action) {
      state.isLoading = false;
      state.teacherSession = action.payload.data;
      state.response = {
        data: action.payload.data,
        type: 'UPDATE_TEACHER_SESSION_SUCCESS'
      };
    },

    // DELETE SESSION
    deleteTeacherSessionSuccess(state, action) {
      state.isLoading = false;
      state.teacherSession = action.payload;
      state.response = {
        data: action.payload.data,
        type: 'DELETE_TEACHER_SESSION_SUCCESS'
      };
    },

    // GET MANAGE SESSION
    getTeacherSessionListSuccess(state, action) {
      state.isLoading = false;
      state.teacherSessionList = action.payload.data;
      state.teacherSessionListMeta = action.payload.meta as ListMeta;
      state.response = {
        data: action.payload.data,
        type: 'GET_TEACHER_SESSION_LIST_SUCCESS'
      };
    },

    // GET MANAGE SESSION
    getGroupedTeacherSessionListSuccess(state, action) {
      state.isLoading = false;
      state.response = {
        data: action.payload.data,
        type: 'GET_TEACHER_SESSION_LIST_SUCCESS'
      };
    },

    // GET MANAGE SESSION
    getAllTeacherSessionListSuccess(state, action) {
      state.isLoading = false;
      state.response = {
        data: action.payload.data,
        type: 'GET_ALL_TEACHER_SESSION_LIST_SUCCESS'
      };
    }
  }
});

// Reducer
export default slice.reducer;

// Actions
// ----------------------------------------------------------------------

export function getSessionBetweenDate(params: any) {
  return async () => {
    dispatch(slice.actions.startLoading());
    const response = await axios.get(`/teacher/session/get`, { params });
    return response;
  };
}

export function getTeacherSessionBetweenDate(teacherId: number, params: any) {
  return async () => {
    dispatch(slice.actions.startLoading());
    const response = await axios.get(`/session/get/${teacherId}/teacher`, { params });
    return response;
  };
}

// teacher
export function createTeacherSession(payload: any) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/teacher/session/create', payload);
      dispatch(slice.actions.createTeacherSessionSuccess(response.data));
    } catch (error: any) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function updateTeacherSession(payload: any, sessionId: string) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.put(`/teacher/session/${sessionId}`, payload);
      dispatch(slice.actions.updateTeacherSessionSuccess(response.data));
    } catch (error: any) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function deleteTeacherSessionById(sessionId: number) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/teacher/session/delete', { sessionId });
      dispatch(slice.actions.deleteTeacherSessionSuccess(response.data));
    } catch (error: any) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// for teacher

export function getTeacherSessionListOfMonth(params: any) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/teacher/session/get/by-month', {
        params
      });
      dispatch(slice.actions.getGroupedTeacherSessionListSuccess(response.data));
    } catch (error: any) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getTeacherSessionListOfDate(params: any) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/teacher/session/get/by-date', {
        params
      });
      dispatch(slice.actions.getTeacherSessionListSuccess(response.data));
    } catch (error: any) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getAllTeacherSessionList() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/teacher/session/get/all');
      dispatch(slice.actions.getAllTeacherSessionListSuccess(response.data));
    } catch (error: any) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function resetState() {
  return async () => {
    dispatch(slice.actions.reset());
  };
}

export function clearResponse() {
  return async () => {
    dispatch(slice.actions.clearResponse());
  };
}

export function clearError() {
  return async () => {
    dispatch(slice.actions.clearError());
  };
}
