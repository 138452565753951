import { createSlice } from '@reduxjs/toolkit';
import { dispatch } from '../store';
// utils
import axios from '../../utils/axios';
import { Session } from '../../@types/session';
import { ListMeta } from '../../@types/table';

// ----------------------------------------------------------------------

type SessionResponse = {
  data: any;
  type: string;
};

type SessionState = {
  isLoading: boolean;
  error: any;
  session: null | Session;
  response: null | SessionResponse;
  sessionList: Session[];
  sessionListMeta: ListMeta;
};

const initialState: SessionState = {
  isLoading: false,
  error: false,
  session: null,
  response: null,
  sessionList: [],
  sessionListMeta: {} as ListMeta
};

const slice = createSlice({
  name: 'session',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // START LOADING
    reset(state) {
      state.isLoading = false;
      state.error = false;
      state.response = null;
      state.session = null;
      state.sessionList = [];
    },

    clearResponse(state) {
      state.response = null;
    },

    clearError(state) {
      state.error = false;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // CREATE SESSION
    createSessionSuccess(state, action) {
      state.isLoading = false;
      state.session = action.payload.data;
      state.response = {
        data: action.payload.data,
        type: 'CREATE_SESSION_SUCCESS'
      };
    },

    // UPDATE SESSION
    updateSessionSuccess(state, action) {
      state.isLoading = false;
      state.session = action.payload.data;
      state.response = {
        data: action.payload.data,
        type: 'UPDATE_SESSION_SUCCESS'
      };
    },

    // DELETE SESSION
    deleteSessionSuccess(state, action) {
      state.isLoading = false;
      state.session = action.payload;
      state.response = {
        data: action.payload.data,
        type: 'DELETE_SESSION_SUCCESS'
      };
    },

    // GET MANAGE SESSION
    getSessionListSuccess(state, action) {
      state.isLoading = false;
      state.sessionList = action.payload.data;
      state.sessionListMeta = action.payload.meta as ListMeta;
      state.response = {
        data: action.payload.data,
        type: 'GET_SESSION_LIST_SUCCESS'
      };
    },

    // GET MANAGE SESSION
    getGroupedSessionListSuccess(state, action) {
      state.isLoading = false;
      state.response = {
        data: action.payload.data,
        type: 'GET_SESSION_LIST_SUCCESS'
      };
    },

    // GET MANAGE SESSION
    getAllSessionListSuccess(state, action) {
      state.isLoading = false;
      state.response = {
        data: action.payload.data,
        type: 'GET_ALL_SESSION_LIST_SUCCESS'
      };
    }
  }
});

// Reducer
export default slice.reducer;

// Actions
// ----------------------------------------------------------------------

export function getSessionBetweenDate(params: any) {
  return async () => {
    dispatch(slice.actions.startLoading());
    // try {
    const response = await axios.get(`/session/get`, { params });
    return response;
    // dispatch(slice.actions.getSessionListSuccess(response.data));
    // } catch (error: any) {
    //   dispatch(slice.actions.hasError(error));
    // }
  };
}

export function getProviderSessionBetweenDate(providerId: number, params: any) {
  return async () => {
    dispatch(slice.actions.startLoading());
    // try {
    const response = await axios.get(`/session/get/${providerId}/provider`, { params });
    return response;
    // dispatch(slice.actions.getSessionListSuccess(response.data));
    // } catch (error: any) {
    //   dispatch(slice.actions.hasError(error));
    // }
  };
}

// provider
export function createSession(payload: any) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/provider/session/create', payload);
      dispatch(slice.actions.createSessionSuccess(response.data));
    } catch (error: any) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function updateSession(payload: any, sessionId: string) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.put(`/provider/session/${sessionId}`, payload);
      dispatch(slice.actions.updateSessionSuccess(response.data));
    } catch (error: any) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function deleteSessionById(sessionId: number) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/provider/session/delete', { sessionId });
      dispatch(slice.actions.deleteSessionSuccess(response.data));
    } catch (error: any) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// for provider

export function getSessionListOfMonth(params: any) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/provider/session/get/by-month', {
        params
      });
      dispatch(slice.actions.getGroupedSessionListSuccess(response.data));
    } catch (error: any) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getSessionListOfDate(params: any) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/provider/session/get/by-date', {
        params
      });
      dispatch(slice.actions.getSessionListSuccess(response.data));
    } catch (error: any) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getAllSessionList() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/provider/session/get/all');
      dispatch(slice.actions.getAllSessionListSuccess(response.data));
    } catch (error: any) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function resetState() {
  return async () => {
    dispatch(slice.actions.reset());
  };
}

export function clearResponse() {
  return async () => {
    dispatch(slice.actions.clearResponse());
  };
}

export function clearError() {
  return async () => {
    dispatch(slice.actions.clearError());
  };
}
