import { createSlice } from '@reduxjs/toolkit';
import { dispatch } from '../store';
// utils
import axios from '../../utils/axios';
import { ScheduledSession } from '../../@types/scheduled-session';
import { ListMeta } from '../../@types/table';

// ----------------------------------------------------------------------

type RequestedSessionResponse = {
  data: any;
  type: string;
};

type RequestedSessionState = {
  isLoading: boolean;
  error: any;
  scheduledSession: null | ScheduledSession;
  response: null | RequestedSessionResponse;
  scheduledSessionList: ScheduledSession[];
  scheduledSessionListMeta: ListMeta;
};

const initialState: RequestedSessionState = {
  isLoading: false,
  error: false,
  scheduledSession: null,
  response: null,
  scheduledSessionList: [],
  scheduledSessionListMeta: {} as ListMeta
};

const slice = createSlice({
  name: 'scheduledSession',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // START LOADING
    reset(state) {
      state.isLoading = false;
      state.error = false;
      state.response = null;
      state.scheduledSession = null;
    },

    clearResponse(state) {
      state.response = null;
    },

    clearError(state) {
      state.error = false;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET REQUESTED SESSION
    getSuccess(state, action) {
      state.isLoading = false;
      state.scheduledSession = action.payload.data;
      state.response = {
        data: action.payload.data,
        type: 'GET_SCHEDULED_SESSION_SUCCESS'
      };
    },

    // CREATE REQUESTED SESSION
    createSuccess(state, action) {
      state.isLoading = false;
      state.scheduledSession = action.payload.data;
      state.response = {
        data: action.payload.data,
        type: 'CREATE_SCHEDULED_SESSION_SUCCESS'
      };
    },

    // UPDATE REQUESTED SESSION
    updateSuccess(state, action) {
      state.isLoading = false;
      state.scheduledSession = action.payload.data;
      state.response = {
        data: action.payload.data,
        type: 'UPDATE_SCHEDULED_SESSION_SUCCESS'
      };
    },

    // DELETE REQUESTED SESSION
    deleteSuccess(state, action) {
      state.isLoading = false;
      state.scheduledSession = null;
      state.response = {
        data: action.payload.data,
        type: 'DELETE_SCHEDULED_SESSION_SUCCESS'
      };
    },

    // GET MANAGE SESSION
    getAllListSuccess(state, action) {
      state.isLoading = false;
      state.response = {
        data: action.payload.data,
        type: 'GET_ALL_SCHEDULED_SESSION_LIST_SUCCESS'
      };
    },

    // GET MANAGE SESSION
    getListSuccess(state, action) {
      state.isLoading = false;
      state.scheduledSessionList = action.payload.data;
      state.scheduledSessionListMeta = action.payload.meta as ListMeta;
      state.response = {
        data: action.payload.data,
        type: 'GET_SCHEDULED_SESSION_LIST_SUCCESS'
      };
    }
  }
});

// Reducer
export default slice.reducer;

// Actions
// ----------------------------------------------------------------------

export function getRecordById(scheduledSessionId: number) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`provider/scheduled-sessions/${scheduledSessionId}`);
      dispatch(slice.actions.getSuccess(response.data));
    } catch (error: any) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function createRecord(payload: any) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/provider/scheduled-sessions/create', payload);
      dispatch(slice.actions.createSuccess(response.data));
    } catch (error: any) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function updateRecord(payload: any, scheduledSessionId: string) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.put(
        `/provider/scheduled-sessions/${scheduledSessionId}`,
        payload
      );
      dispatch(slice.actions.updateSuccess(response.data));
    } catch (error: any) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function deleteRecord(scheduledSessionId: number) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.delete(`/provider/scheduled-sessions/${scheduledSessionId}`);
      dispatch(slice.actions.deleteSuccess(response.data));
    } catch (error: any) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function verifyRequestedSession(token: string) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(`guest/verify/${token}`);
      dispatch(slice.actions.getSuccess(response.data));
    } catch (error: any) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getAllocatedSlotsByDate(token: string, params: any) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/guest/allocated-slots/by-date/${token}`, {
        params
      });
      dispatch(slice.actions.getListSuccess(response.data));
    } catch (error: any) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getSessionListOfCurrentWeek() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/provider/scheduled-sessions/current-week');
      dispatch(slice.actions.getAllListSuccess(response.data));
    } catch (error: any) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getSessionListOfCurrentWeekStats() {
  return async () => {
    dispatch(slice.actions.startLoading());
    // try {
    const response = await axios.get('/provider/scheduled-sessions/current-week/stats');
    return response;
    // dispatch(slice.actions.getAllListSuccess(response.data));
    // } catch (error: any) {
    //   dispatch(slice.actions.hasError(error));
    // }
  };
}

export function updateGuestSession(payload: any, token: string) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.put(`/guest/scheduled-sessions/${token}`, payload);
      dispatch(slice.actions.updateSuccess(response.data));
    } catch (error: any) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getListByDate(params: any) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/provider/scheduled-sessions/by-date', {
        params
      });
      dispatch(slice.actions.getListSuccess(response.data));
    } catch (error: any) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getSharedSessionListOfCurrentWeek(token: string) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/shared/scheduled-sessions/current-week/${token}`);
      dispatch(slice.actions.getAllListSuccess(response.data));
    } catch (error: any) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getSharedListByDate(token: string, params: any) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/shared/scheduled-sessions/by-date/${token}`, {
        params
      });
      dispatch(slice.actions.getListSuccess(response.data));
    } catch (error: any) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function resetState() {
  return async () => {
    dispatch(slice.actions.reset());
  };
}

export function clearResponse() {
  return async () => {
    dispatch(slice.actions.clearResponse());
  };
}

export function clearError() {
  return async () => {
    dispatch(slice.actions.clearError());
  };
}
