import { createSlice } from '@reduxjs/toolkit';
import { dispatch } from '../store';
// utils
import axios from '../../utils/axios';
import { DirectorHoliday } from '../../@types/director-holiday';
import { ListMeta } from '../../@types/table';

// ----------------------------------------------------------------------

type DirectorHolidayResponse = {
  data: any;
  type: string;
};

type DirectorHolidayState = {
  isLoading: boolean;
  error: any;
  directorHoliday: null | DirectorHoliday;
  response: null | DirectorHolidayResponse;
  directorHolidayList: DirectorHoliday[];
  directorHolidayListMeta: ListMeta;
};

const initialState: DirectorHolidayState = {
  isLoading: false,
  error: false,
  directorHoliday: null,
  response: null,
  directorHolidayList: [],
  directorHolidayListMeta: {} as ListMeta
};

const slice = createSlice({
  name: 'directorHoliday',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // START LOADING
    reset(state) {
      state.isLoading = false;
      state.error = false;
      state.response = null;
      state.directorHoliday = null;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET HOLIDAY
    getDirectorHolidaySuccess(state, action) {
      state.isLoading = false;
      state.directorHoliday = action.payload.data;
      state.response = {
        data: action.payload.success,
        type: 'GET_DIRECTOR_HOLIDAY_SUCCESS'
      };
    },

    // CREATE HOLIDAY
    createDirectorHolidaySuccess(state, action) {
      state.isLoading = false;
      state.directorHoliday = action.payload.data;
      state.response = {
        data: action.payload.data,
        type: 'CREATE_DIRECTOR_HOLIDAY_SUCCESS'
      };
    },

    // UPDATE HOLIDAY
    updateDirectorHolidaySuccess(state, action) {
      state.isLoading = false;
      state.directorHoliday = action.payload.data;
      state.response = {
        data: action.payload.data,
        type: 'UPDATE_DIRECTOR_HOLIDAY_SUCCESS'
      };
    },

    // DELETE HOLIDAY
    deleteDirectorHolidaySuccess(state, action) {
      state.isLoading = false;
      state.response = {
        data: action.payload.data,
        type: 'DELETE_DIRECTOR_HOLIDAY_SUCCESS'
      };
    },

    // GET MANAGE HOLIDAY
    getDirectorHolidayListSuccess(state, action) {
      state.isLoading = false;
      state.directorHolidayList = action.payload.data;
      state.directorHolidayListMeta = action.payload.meta as ListMeta;
      state.response = {
        data: action.payload.data,
        type: 'GET_DIRECTOR_HOLIDAY_LIST_SUCCESS'
      };
    }
  }
});

// Reducer
export default slice.reducer;

// Actions
// ----------------------------------------------------------------------

export function getDirectorHolidayById(holidayId: string) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`holiday/get/${holidayId}`);
      dispatch(slice.actions.getDirectorHolidaySuccess(response.data));
    } catch (error: any) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function createDirectorHoliday(payload: any) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/director/holiday/create', payload);
      dispatch(slice.actions.createDirectorHolidaySuccess(response.data));
    } catch (error: any) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function updateDirectorHoliday(payload: any) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/director/holiday/update', payload);
      dispatch(slice.actions.updateDirectorHolidaySuccess(response.data));
    } catch (error: any) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getDirectorHolidayList() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/director/holiday/get/all');
      dispatch(slice.actions.getDirectorHolidayListSuccess(response.data));
    } catch (error: any) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function deleteDirectorHolidayById(holidayId: string) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/director/holiday/delete', { holidayId });
      dispatch(slice.actions.deleteDirectorHolidaySuccess(response.data));
    } catch (error: any) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// for guest
export function getDirectorHolidayListByMonthForGuest(month: number) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/teacher/holiday/month/${month}/get`);
      dispatch(slice.actions.getDirectorHolidayListSuccess(response.data));
    } catch (error: any) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// for guest
export function resetState() {
  return async () => {
    dispatch(slice.actions.reset());
  };
}
