import { createSlice } from '@reduxjs/toolkit';
import { dispatch } from '../store';
// utils
import axios from '../../utils/axios';
import { ClassGrading } from '../../@types/school';
import { ListMeta, ListQueryParams } from '../../@types/table';

// ----------------------------------------------------------------------

type ClassGradingResponse = {
  data: any;
  type: string;
};

type ClassGradingState = {
  isLoading: boolean;
  error: any;
  classGrading: null | any;
  response: null | ClassGradingResponse;
  gradingList: ClassGrading[];
  gradingListMeta: ListMeta;
};

const initialState: ClassGradingState = {
  isLoading: false,
  error: false,
  classGrading: null,
  response: null,
  gradingList: [],
  gradingListMeta: {} as ListMeta
};

// ----------------------------------------------------------------------
const slice = createSlice({
  name: 'classGrading',
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
    },

    // START LOADING
    reset(state) {
      state.isLoading = false;
      state.error = false;
      state.response = null;
      state.classGrading = null;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET Grading
    getGradingSuccess(state, action) {
      state.isLoading = false;
      state.classGrading = action.payload.data;
      state.response = {
        data: action.payload.success,
        type: 'GET_DIRECTOR_CLASS_GRADING_SUCCESS'
      };
    },

    // CREATE Grading
    createGradingSuccess(state, action) {
      state.isLoading = false;
      state.classGrading = action.payload.data;
      state.response = {
        data: action.payload.data,
        type: 'CREATE_DIRECTOR_CLASS_GRADING_SUCCESS'
      };
    },

    // UPDATE Grading
    updateGradingSuccess(state, action) {
      state.isLoading = false;
      state.classGrading = action.payload.data;
      state.response = {
        data: action.payload.data,
        type: 'UPDATE_DIRECTOR_CLASS_GRADING_SUCCESS'
      };
    },

    // DELETE Grading
    deleteGradingSuccess(state, action) {
      state.isLoading = false;
      state.classGrading = action.payload;
      state.response = {
        data: action.payload.data,
        type: 'DELETE_DIRECTOR_CLASS_GRADING_SUCCESS'
      };
    },

    // GET MANAGE Grading
    getGradingListSuccess(state, action) {
      state.isLoading = false;
      state.gradingList = action.payload.data;
      state.gradingListMeta = action.payload.meta as ListMeta;
      state.response = {
        data: action.payload.data,
        type: 'GET_DIRECTOR_NEWS_LIST_SUCCESS'
      };
    }
  }
});
// Reducer
export default slice.reducer;

export function getClassGradeById(recordId: number) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/director/grading/get/${recordId}`);
      dispatch(slice.actions.getGradingSuccess(response.data));
    } catch (error: any) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
export function getAllClassGradeList() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/director/grading/get/all');
      dispatch(slice.actions.getGradingSuccess(response.data));
    } catch (error: any) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
export function getClassGradeList(params: ListQueryParams) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/director/grading/paginate', {
        params: {
          'pagination[pageSize]': params.pageSize,
          'pagination[page]': params.page
        }
      });
      dispatch(slice.actions.getGradingListSuccess(response.data));
    } catch (error: any) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function createClassGrade(payload: any) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/director/grading/create', payload);
      dispatch(slice.actions.createGradingSuccess(response.data));
    } catch (error: any) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
export function updateClassGrade(recordId: number, payload: any) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(`/director/grading/update/${recordId}`, payload);
      dispatch(slice.actions.updateGradingSuccess(response.data));
    } catch (error: any) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function deleteClassGradeById(id: number | null) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/director/grading/delete', { id });
      dispatch(slice.actions.deleteGradingSuccess(response.data));
    } catch (error: any) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getTeacherClassGrading() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/teacher/grading');
      dispatch(slice.actions.getGradingSuccess(response.data));
    } catch (error: any) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getStudentClassGrading() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/student/grading');
      dispatch(slice.actions.getGradingSuccess(response.data));
    } catch (error: any) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
// for guest
export function resetState() {
  return async () => {
    dispatch(slice.actions.reset());
  };
}
