import { createSlice } from '@reduxjs/toolkit';
import { dispatch } from '../store';
// utils
import axios from '../../utils/axios';
import { PersonalCode } from '../../@types/school';
import { ListMeta } from '../../@types/table';

// ----------------------------------------------------------------------

type PersonalCodeResponse = {
  data: any;
  type: string;
};

type PersonalCodeState = {
  isLoading: boolean;
  error: any;
  personalCode: null | PersonalCode;
  response: null | PersonalCodeResponse;
  personalCodeList: PersonalCode[];
  personalCodeListMeta: ListMeta;
};

const initialState: PersonalCodeState = {
  isLoading: false,
  error: false,
  personalCode: null,
  response: null,
  personalCodeList: [],
  personalCodeListMeta: {} as ListMeta
};

const slice = createSlice({
  name: 'teacherPersonalCode',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // START LOADING
    reset(state) {
      state.isLoading = false;
      state.error = false;
      state.response = null;
      state.personalCode = null;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET PERSONAL CODE
    getPersonalCodeSuccess(state, action) {
      state.isLoading = false;
      state.personalCode = action.payload.data;
      state.response = {
        data: action.payload.success,
        type: 'GET_PERSONAL_CODE_SUCCESS'
      };
    },

    // CREATE PERSONAL CODE
    createPersonalCodeSuccess(state, action) {
      state.isLoading = false;
      state.personalCode = action.payload.data;
      state.response = {
        data: action.payload.data,
        type: 'CREATE_PERSONAL_CODE_SUCCESS'
      };
    },

    // DELETE PERSONAL CODE
    deletePersonalCodeSuccess(state, action) {
      state.isLoading = false;
      state.response = {
        data: action.payload.data,
        type: 'DELETE_PERSONAL_CODE_SUCCESS'
      };
    }
  }
});

// Reducer
export default slice.reducer;

// Actions
// ----------------------------------------------------------------------

export function getPersonalCodeByTeacherId(teacherId: string) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`teacher/${teacherId}/personal-code`);
      dispatch(slice.actions.getPersonalCodeSuccess(response.data));
    } catch (error: any) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function createPersonalCode(payload: any) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/teacher/personal-code/create', payload);
      dispatch(slice.actions.createPersonalCodeSuccess(response.data));
    } catch (error: any) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function deletePersonalCode(teacherId: string) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/teacher/personal-code/delete', {
        teacherId: teacherId
      });
      dispatch(slice.actions.deletePersonalCodeSuccess(response.data));
    } catch (error: any) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function resetState() {
  return async () => {
    dispatch(slice.actions.reset());
  };
}
