import { useState, useEffect } from 'react';
import { useSnackbar } from 'notistack';
// material
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText
} from '@mui/material';
// redux
import { RootState, useDispatch, useSelector } from '../../../redux/store';
import { deleteHomeworkById } from '../../../redux/slices/homework';

// ----------------------------------------------------------------------
type HomeworkDeleteConfirmationDialogProps = {
  recordId: string;
  openDialog: boolean;
  actionCancelled: VoidFunction;
  actionDeleted: VoidFunction;
};

export default function HomeworkDeleteConfirmationDialog({
  actionCancelled,
  openDialog,
  actionDeleted,
  recordId
}: HomeworkDeleteConfirmationDialogProps) {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const { error: homeworkError, response: homeworkResponse } = useSelector(
    (state: RootState) => state.homework
  );
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    setOpen(openDialog);
  }, [openDialog]);

  useEffect(() => {
    console.log(homeworkError);
    if (homeworkError) {
      enqueueSnackbar(homeworkError.message, {
        variant: 'error'
      });
      handleClose();
    }
  }, [homeworkError]);

  useEffect(() => {
    if (homeworkResponse && homeworkResponse.type === 'DELETE_HOMEWORK_SUCCESS') {
      enqueueSnackbar('Delete success', {
        variant: 'success'
      });
      handleDeleted();
    }
  }, [homeworkResponse]);

  const handleClose = () => {
    setOpen(false);
    actionCancelled();
  };

  const handleDelete = () => {
    dispatch(deleteHomeworkById(recordId));
  };

  const handleDeleted = () => {
    setOpen(false);
    actionDeleted();
  };

  return (
    <div>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Delete Confirmation</DialogTitle>
        <DialogContent>
          <DialogContentText>Are you sure you want to delete the record?</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="inherit">
            Cancel
          </Button>
          <Button onClick={handleDelete} variant="contained" color="error">
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
