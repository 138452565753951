import { GuestProviderContext } from 'contexts/GuestProviderContext';
import { useContext } from 'react';

// ----------------------------------------------------------------------

const useGuestProviderAuth = () => {
  const context = useContext(GuestProviderContext);

  if (!context) throw new Error('Auth context must be use inside AuthProvider');

  return context;
};

export default useGuestProviderAuth;
