import { createSlice } from '@reduxjs/toolkit';
import { dispatch } from '../store';
// utils
import axios from '../../utils/axios';
import { AgencyManager } from '../../@types/agency';
import { ListMeta, ListQueryParams } from '../../@types/table';

// ----------------------------------------------------------------------

type AgencyResponse = {
  data: any;
  type: string;
};

type AgencyState = {
  isLoading: boolean;
  error: any;
  agency: null | AgencyManager;
  response: null | AgencyResponse;
  agencyList: AgencyManager[];
  agencyListMeta: ListMeta;
};

const initialState: AgencyState = {
  isLoading: false,
  error: false,
  agency: null,
  response: null,
  agencyList: [],
  agencyListMeta: {} as ListMeta
};

const slice = createSlice({
  name: 'agency',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // START LOADING
    reset(state) {
      state.isLoading = false;
      state.error = false;
      state.response = null;
      state.agency = null;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET AGENCY
    getAgencySuccess(state, action) {
      state.isLoading = false;
      state.agency = action.payload.data;
      state.response = {
        data: action.payload.success,
        type: 'GET_AGENCY_SUCCESS'
      };
    },

    // CREATE AGENCY
    createAgencySuccess(state, action) {
      state.isLoading = false;
      state.agency = action.payload.data;
      state.response = {
        data: action.payload.data,
        type: 'CREATE_AGENCY_SUCCESS'
      };
    },

    // UPDATE AGENCY
    updateAgencySuccess(state, action) {
      state.isLoading = false;
      state.agency = action.payload.data;
      state.response = {
        data: action.payload.data,
        type: 'UPDATE_AGENCY_SUCCESS'
      };
    },

    // DELETE AGENCY
    deleteAgencySuccess(state, action) {
      state.isLoading = false;
      state.agency = action.payload;
      state.response = {
        data: action.payload.data,
        type: 'DELETE_AGENCY_SUCCESS'
      };
    },

    // GET MANAGE AGENCIES
    getAgencyListSuccess(state, action) {
      state.isLoading = false;
      state.agencyList = action.payload.data;
      state.agencyListMeta = action.payload.meta as ListMeta;
      state.response = {
        data: action.payload.data,
        type: 'GET_AGENCY_LIST_SUCCESS'
      };
    },

    // RESET PASSWORD
    resetAgencyPasswordSuccess(state, action) {
      state.isLoading = false;
      state.response = {
        data: action.payload.success,
        type: 'RESET_AGENCY_PASSWORD_SUCCESS'
      };
    },

    // GENERAL SETTING
    updateGeneralSettingSuccess(state, action) {
      state.isLoading = false;
      state.response = {
        data: action.payload.success,
        type: 'UPDATE_GENERAL_SETTING_SUCCESS'
      };
    },

    // GENERAL SETTING
    getGeneralSettingSuccess(state, action) {
      state.isLoading = false;
      state.response = {
        data: action.payload.data,
        type: 'GET_GENERAL_SETTING_SUCCESS'
      };
    }
  }
});

// Reducer
export default slice.reducer;

// Actions
// ----------------------------------------------------------------------

export function getAgencyById(agencyId: string) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`agency/get/${agencyId}`);
      dispatch(slice.actions.getAgencySuccess(response.data));
    } catch (error: any) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function deleteAgencyById(agencyId: string) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/agency/delete', { agencyId: agencyId });
      dispatch(slice.actions.deleteAgencySuccess(response.data));
    } catch (error: any) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function createAgency(payload: any) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/agency/create', payload);
      dispatch(slice.actions.createAgencySuccess(response.data));
    } catch (error: any) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function updateAgency(payload: any) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/agency/update', payload);
      dispatch(slice.actions.updateAgencySuccess(response.data));
    } catch (error: any) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function updateGeneralSetting(payload: any) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/agency/general-setting/update', payload);
      dispatch(slice.actions.updateGeneralSettingSuccess(response.data));
    } catch (error: any) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getGeneralSetting() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/agency/general-setting');
      dispatch(slice.actions.getGeneralSettingSuccess(response.data));
    } catch (error: any) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getAgencyList(params: ListQueryParams) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/agency/get/all', {
        params: {
          'pagination[pageSize]': params.pageSize,
          'pagination[page]': params.page
        }
      });
      dispatch(slice.actions.getAgencyListSuccess(response.data));
    } catch (error: any) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function resetAgencyPassword(payload: any) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/agency/reset-password', payload);
      dispatch(slice.actions.resetAgencyPasswordSuccess(response.data));
    } catch (error: any) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function updateAgencySetting(agencyId: string, payload: any) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(`/agency/setting/${agencyId}`, payload);
      dispatch(slice.actions.updateAgencySuccess(response.data));
    } catch (error: any) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function resetState() {
  return async () => {
    dispatch(slice.actions.reset());
  };
}
