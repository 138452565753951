import * as Yup from 'yup';
import { useSnackbar } from 'notistack';
import React, { useState, useEffect } from 'react';
import { Icon } from '@iconify/react';
import closeFill from '@iconify/icons-eva/close-fill';
// material
import {
  Box,
  Backdrop,
  Paper,
  Typography,
  Stack,
  TextField,
  Alert,
  Button,
  FormHelperText
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
// redux
import { RootState, useDispatch, useSelector } from '../../../redux/store';
// hooks
import useIsMountedRef from '../../../hooks/useIsMountedRef';
//
import Scrollbar from '../../Scrollbar';
import { MIconButton } from '../../@material-extend';
import { Form, FormikProvider, useFormik, FieldArray, ErrorMessage } from 'formik';
import { updateGeneralSetting, getGeneralSetting } from 'redux/slices/agency';
// ----------------------------------------------------------------------

const DRAWER_WIDTH = 360;

type InitialValues = {
  emails: Array<string>;
};

type GeneralSettingsFormProps = {
  openForm: boolean;
  formClosed: VoidFunction;
};

export default function GeneralSettingsForm({ openForm, formClosed }: GeneralSettingsFormProps) {
  const dispatch = useDispatch();
  const isMountedRef = useIsMountedRef();
  const [open, setOpen] = useState(false);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const { error: agencyError, response: agencyResponse } = useSelector(
    (state: RootState) => state.agency
  );
  const [alertError, setAlertError] = useState('');

  // useEffect(() => {
  //   if (open) {
  //     document.body.style.overflow = 'hidden';
  //   } else {
  //     document.body.style.overflow = 'unset';
  //   }
  // }, [open]);

  useEffect(() => {
    setOpen(openForm);
  }, [openForm]);

  useEffect(() => {
    dispatch(getGeneralSetting());
  }, []);

  useEffect(() => {
    console.log(agencyError);
    if (agencyError) {
      setAlertError(agencyError.message);
    }
  }, [agencyError]);

  useEffect(() => {
    if (agencyResponse) {
      if (agencyResponse.type === 'UPDATE_GENERAL_SETTING_SUCCESS') {
        enqueueSnackbar('Setting updated success', {
          variant: 'success',
          action: (key) => (
            <MIconButton size="small" onClick={() => closeSnackbar(key)}>
              <Icon icon={closeFill} />
            </MIconButton>
          )
        });
        handleClose();
      } else if (agencyResponse.type === 'GET_GENERAL_SETTING_SUCCESS') {
        if (agencyResponse.data.notifyEmails) {
          setFieldValue('emails', agencyResponse.data.notifyEmails.split(','));
        } else {
          setFieldValue('emails', []);
        }
      }
    }
  }, [agencyResponse]);

  const handleClose = () => {
    setOpen(false);
    formik.resetForm();
    formClosed();
  };

  const GeneralSettingsSchema = Yup.object().shape({
    emails: Yup.array().min(1, 'Must have 1 email').required('Required')
  });

  const formik = useFormik<InitialValues>({
    initialValues: {
      emails: []
    },
    validationSchema: GeneralSettingsSchema,
    onSubmit: async (values, { setSubmitting, resetForm, setErrors }) => {
      try {
        await dispatch(updateGeneralSetting(values));
        if (isMountedRef.current) {
          setSubmitting(false);
        }
      } catch (error: any) {
        resetForm();
        if (isMountedRef.current) {
          setSubmitting(false);
        }
      }
    }
  });

  const { handleSubmit, isSubmitting, getFieldProps, values, setFieldValue } = formik;

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Backdrop
          sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={open}
          onClick={handleClose}
        />

        <Box
          sx={[
            {
              top: 0,
              bottom: 0,
              right: 0,
              position: 'fixed',
              zIndex: 2001,
              ...(open && { right: 0 })
            },
            (theme) => ({
              [theme.breakpoints.down('sm')]: {
                top: '70px'
              }
            })
          ]}
        >
          <Paper
            sx={{
              height: 1,
              width: '0px',
              borderRadius: 0,
              overflow: 'hidden',
              boxShadow: (theme) => theme.customShadows.z24,
              transition: (theme) => theme.transitions.create('width'),
              ...(open && { width: DRAWER_WIDTH })
            }}
          >
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              sx={{ py: 2, pr: 1, pl: 2.5 }}
            >
              <Typography variant="subtitle1">Agency Settings</Typography>
              <MIconButton onClick={handleClose}>
                <Icon icon={closeFill} width={20} height={20} />
              </MIconButton>
            </Stack>

            <Scrollbar sx={{ height: 1 }}>
              <Stack spacing={4} sx={{ pt: 3, px: 3, pb: 15 }}>
                <Stack spacing={1.5}>
                  {alertError && <Alert severity="error">{alertError}</Alert>}

                  <Typography variant="subtitle2">Notification emails</Typography>

                  <FieldArray
                    name="emails"
                    render={(arrayHelpers) => (
                      <>
                        {values.emails.map((friend, index) => (
                          <React.Fragment key={index}>
                            <Stack
                              direction={{ xs: 'column', sm: 'row' }}
                              spacing={{ xs: 3, sm: 2 }}
                            >
                              <TextField
                                fullWidth
                                variant="filled"
                                size="small"
                                type="email"
                                margin="none"
                                {...getFieldProps(`emails.${index}`)}
                              />
                              <MIconButton onClick={() => arrayHelpers.remove(index)}>
                                <Icon icon={closeFill} width={20} height={20} />
                              </MIconButton>
                            </Stack>
                          </React.Fragment>
                        ))}

                        <Button
                          size="small"
                          variant="contained"
                          onClick={() => arrayHelpers.push('')}
                          sx={{
                            width: '100px'
                          }}
                        >
                          Add email
                        </Button>
                      </>
                    )}
                  />

                  <ErrorMessage
                    name="emails"
                    render={(msg) => (
                      <FormHelperText error={true} variant="filled">
                        {msg}
                      </FormHelperText>
                    )}
                  />
                </Stack>

                <Stack spacing={1.5}>
                  <LoadingButton
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                    loading={isSubmitting}
                  >
                    Save
                  </LoadingButton>
                </Stack>
              </Stack>
            </Scrollbar>
          </Paper>
        </Box>
      </Form>
    </FormikProvider>
  );
}
