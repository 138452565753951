import { createSlice } from '@reduxjs/toolkit';
import { dispatch } from '../store';
// utils
import axios from '../../utils/axios';
import { Teacher } from '../../@types/school';
import { ListMeta, ListQueryParams } from '../../@types/table';

// ----------------------------------------------------------------------

type TeacherResponse = {
  data: any;
  type: string;
};

type TeacherState = {
  isLoading: boolean;
  error: any;
  teacher: null | Teacher;
  response: null | TeacherResponse;
  teacherList: Teacher[];
  teacherListMeta: ListMeta;
  teacherPaginatedList: Teacher[];
  teacherPaginatedListMeta: ListMeta;
};

const initialState: TeacherState = {
  isLoading: false,
  error: false,
  teacher: null,
  response: null,
  teacherList: [],
  teacherListMeta: {} as ListMeta,
  teacherPaginatedList: [],
  teacherPaginatedListMeta: {} as ListMeta
};

const slice = createSlice({
  name: 'teacher',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // START LOADING
    reset(state) {
      state.isLoading = false;
      state.error = false;
      state.response = null;
      state.teacher = null;
    },

    // START LOADING
    clearError(state) {
      state.error = false;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;

      state.error = action.payload;
    },

    deleteSuccess(state, action) {
      state.isLoading = false;
      state.teacher = action.payload;
      state.response = {
        data: action.payload.data,
        type: 'DELETE_TEACHER_SUCCESS'
      };
    },

    updateSuccess(state, action) {
      state.isLoading = false;
      state.teacher = action.payload.data;
      state.response = {
        data: action.payload.data,
        type: 'UPDATE_TEACHER_SUCCESS'
      };
    },

    createSuccess(state, action) {
      state.isLoading = false;
      state.teacher = action.payload.data;
      state.response = {
        data: action.payload.data,
        type: 'CREATE_TEACHER_SUCCESS'
      };
    },

    getListSuccess(state, action) {
      state.isLoading = false;
      state.teacherList = action.payload.data;
      state.teacherListMeta = action.payload.meta as ListMeta;
      state.response = {
        data: action.payload.data,
        type: 'GET_TEACHER_LIST_SUCCESS'
      };
    },

    getPaginatedListSuccess(state, action) {
      state.isLoading = false;
      state.teacherPaginatedList = action.payload.data;
      state.teacherPaginatedListMeta = action.payload.meta as ListMeta;
      state.response = {
        data: action.payload.data,
        type: 'GET_TEACHER_PAGINATED_LIST_SUCCESS'
      };
    },

    getSuccess(state, action) {
      state.isLoading = false;
      state.teacher = action.payload.data;
      state.response = {
        data: action.payload.data,
        type: 'GET_TEACHER_SUCCESS'
      };
    },

    // UPDATE AGENCY
    updateTeacherSettingSuccess(state, action) {
      state.isLoading = false;
      state.teacher = action.payload.data;
      state.response = {
        data: action.payload.data,
        type: 'UPDATE_TEACHER_SETTING_SUCCESS'
      };
    },

    getTeacherByPersonalCodeSuccess(state, action) {
      state.isLoading = false;
      state.teacher = action.payload.data;
      state.response = {
        data: action.payload.success,
        type: 'GET_TEACHER_BY_PERSONAL_CODE_SUCCESS'
      };
    }
  }
});

// Reducer
export default slice.reducer;

// Actions
// ----------------------------------------------------------------------

export function getTeacherByID(recordId: number) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/teacher/${recordId}`);
      dispatch(slice.actions.getSuccess(response.data));
    } catch (error: any) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function updateTeacherSetting(recordId: number, payload: any) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(`/teacher/setting/${recordId}`, payload);
      dispatch(slice.actions.updateTeacherSettingSuccess(response.data));
    } catch (error: any) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getAllTeacherList() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/teacher/get/all');
      dispatch(slice.actions.getListSuccess(response.data));
      return response;
    } catch (error: any) {
      dispatch(slice.actions.hasError(error));
      return null;
    }
  };
}

export function getTeacherList(params: ListQueryParams) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/teachers/paginate', {
        params: {
          'pagination[pageSize]': params.pageSize,
          'pagination[page]': params.page
        }
      });
      dispatch(slice.actions.getPaginatedListSuccess(response.data));
      return response;
    } catch (error: any) {
      dispatch(slice.actions.hasError(error));
      return null;
    }
  };
}

export function deleteTeacher(recordId: number) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.delete(`/teacher/${recordId}`);
      dispatch(slice.actions.deleteSuccess(response.data));
    } catch (error: any) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function updateTeacher(recordId: number, payload: any) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.put(`/teacher/${recordId}`, payload);
      dispatch(slice.actions.updateSuccess(response.data));
    } catch (error: any) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function createTeacher(payload: any) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(`/teacher/`, payload);
      dispatch(slice.actions.createSuccess(response.data));
    } catch (error: any) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function resetState() {
  return async () => {
    dispatch(slice.actions.reset());
  };
}

// for guest
export function clearError() {
  return async () => {
    dispatch(slice.actions.clearError());
  };
}

// for guest
export function getTeacherByPersonalCode(personalCode: string) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/teacher/find', {
        personalCode: personalCode
      });
      dispatch(slice.actions.getTeacherByPersonalCodeSuccess(response.data));
    } catch (error: any) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getAllTeacherListForDirector() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/director/get/all/teachers');
      dispatch(slice.actions.getListSuccess(response.data));
      return response;
    } catch (error: any) {
      dispatch(slice.actions.hasError(error));
      return null;
    }
  };
}
