import { createSlice } from '@reduxjs/toolkit';
import { dispatch } from '../store';
// utils
import axios from '../../utils/axios';
import { ProviderZipcodePriority } from '../../@types/provider';

// ----------------------------------------------------------------------

type ProviderZipcodePriorityResponse = {
  data: any;
  type: string;
};

type ProviderZipcodePriorityState = {
  isLoading: boolean;
  error: any;
  providerZipcodePriority: null | ProviderZipcodePriority;
  response: null | ProviderZipcodePriorityResponse;
  providerZipcodePriorityList: ProviderZipcodePriority[];
};

const initialState: ProviderZipcodePriorityState = {
  isLoading: false,
  error: false,
  providerZipcodePriority: null,
  response: null,
  providerZipcodePriorityList: []
};

const slice = createSlice({
  name: 'providerZipcodePriority',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // START LOADING
    reset(state) {
      state.isLoading = false;
      state.error = false;
      state.response = null;
      state.providerZipcodePriority = null;
    },

    // START LOADING
    clearError(state) {
      state.error = false;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;

      state.error = action.payload;
    },

    deleteProviderZipcodePrioritySuccess(state, action) {
      state.isLoading = false;
      state.providerZipcodePriority = action.payload;
      state.response = {
        data: action.payload.data,
        type: 'DELETE_PROVIDER_ZIPCODE_PRIORITY_SUCCESS'
      };
    },

    updateProviderZipcodePrioritySuccess(state, action) {
      state.isLoading = false;
      state.providerZipcodePriority = action.payload.data;
      state.response = {
        data: action.payload.data,
        type: 'UPDATE_PROVIDER_ZIPCODE_PRIORITY_SUCCESS'
      };
    },

    createProviderZipcodePrioritySuccess(state, action) {
      state.isLoading = false;
      state.providerZipcodePriority = action.payload.data;
      state.response = {
        data: action.payload.data,
        type: 'CREATE_PROVIDER_ZIPCODE_PRIORITY_SUCCESS'
      };
    },

    getProviderZipcodePriorityListSuccess(state, action) {
      state.isLoading = false;
      state.providerZipcodePriorityList = action.payload.data;
      state.response = {
        data: action.payload.data,
        type: 'GET_PROVIDER_ZIPCODE_PRIORITY_LIST_SUCCESS'
      };
    },

    getProviderZipcodePrioritySuccess(state, action) {
      state.isLoading = false;
      state.providerZipcodePriority = action.payload.data;
      state.response = {
        data: action.payload.data,
        type: 'GET_PROVIDER_ZIPCODE_PRIORITY_SUCCESS'
      };
    }
  }
});

// Reducer
export default slice.reducer;

// Actions
// ----------------------------------------------------------------------

export function getProviderZipcodePriorityByID(recordId: number) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/provider/zip-codes/priority/${recordId}`);
      dispatch(slice.actions.getProviderZipcodePrioritySuccess(response.data));
    } catch (error: any) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getProviderZipcodePriorityList() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/provider/zip-codes/priorities');
      dispatch(slice.actions.getProviderZipcodePriorityListSuccess(response.data));
      return response;
    } catch (error: any) {
      dispatch(slice.actions.hasError(error));
      return null;
    }
  };
}

export function deleteProviderZipcodePriority(recordId: number) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.delete(`/provider/zip-codes/priority/${recordId}`);
      dispatch(slice.actions.deleteProviderZipcodePrioritySuccess(response.data));
    } catch (error: any) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function updateProviderZipcodePriority(recordId: number, payload: any) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.put(`/provider/zip-codes/priority/${recordId}`, payload);
      dispatch(slice.actions.updateProviderZipcodePrioritySuccess(response.data));
    } catch (error: any) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function createProviderZipcodePriority(payload: any) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(`/provider/zip-codes/priority/`, payload);
      dispatch(slice.actions.createProviderZipcodePrioritySuccess(response.data));
    } catch (error: any) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getProviderZipcodePriorityListByProviderID(providerId: string) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/provider/${providerId}/zip-codes/priorities`);
      dispatch(slice.actions.getProviderZipcodePriorityListSuccess(response.data));
      return response;
    } catch (error: any) {
      dispatch(slice.actions.hasError(error));
      return null;
    }
  };
}

export function resetState() {
  return async () => {
    dispatch(slice.actions.reset());
  };
}

// for guest
export function clearError() {
  return async () => {
    dispatch(slice.actions.clearError());
  };
}
