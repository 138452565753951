// ----------------------------------------------------------------------

function path(root: string, sublink: string) {
  return `${root}${sublink}`;
}

const ROOTS_AUTH = '/auth';
const ROOTS_DASHBOARD = '/dashboard';
const ROOTS_PROVIDER = '/provider';

// ----------------------------------------------------------------------

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, '/login'),
  loginUnprotected: path(ROOTS_AUTH, '/login-unprotected'),
  resetPassword: path(ROOTS_AUTH, '/reset-password'),
  verify: path(ROOTS_AUTH, '/verify')
};

export const PATH_PAGE = {
  comingSoon: '/coming-soon',
  maintenance: '/maintenance',
  about: '/about-us',
  contact: '/contact-us',
  faqs: '/faqs',
  page404: '/404',
  page500: '/500',
  components: '/components'
};

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  agency: {
    root: path(ROOTS_DASHBOARD, '/agency'),
    list: path(ROOTS_DASHBOARD, '/agency/list')
  },
  provider: {
    root: path(ROOTS_DASHBOARD, '/provider')
  },
  class: {
    root: path(ROOTS_DASHBOARD, '/class'),
    list: path(ROOTS_DASHBOARD, '/class/list')
  },
  student: {
    root: path(ROOTS_DASHBOARD, '/student'),
    list: path(ROOTS_DASHBOARD, '/home/list')
  },
  teacher: {
    root: path(ROOTS_DASHBOARD, '/teacher/news/list')
  },
  studentNews: {
    root: path(ROOTS_DASHBOARD, '/student/news/view')
  }
};

export const PATH_GUEST = {
  root: ROOTS_PROVIDER,
  home: path(ROOTS_PROVIDER, '/home'),
  clearanceList: path(ROOTS_PROVIDER, '/h/clearances'),
  billingHome: path(ROOTS_PROVIDER, '/h/billing'),
  newsList: path(ROOTS_PROVIDER, '/h/news'),
  findProvider: 'find-provider',
  fintTeacher: 'find-teacher'
};
