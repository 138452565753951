import { createSlice } from '@reduxjs/toolkit';
import { dispatch } from '../store';
// utils
import axios from '../../utils/axios';
import { TeacherBilling } from '../../@types/teacher-billing';
import { ListMeta } from '../../@types/table';
// ----------------------------------------------------------------------

type TeacherBillingResponse = {
  data: any;
  type: string;
};

type TeacherBillingState = {
  isLoading: boolean;
  error: any;
  teacherBilling: null | any;
  response: null | TeacherBillingResponse;
  teacherBillingList: TeacherBilling[];
  teacherBillingListMeta: ListMeta;
};

const initialState: TeacherBillingState = {
  isLoading: false,
  error: false,
  teacherBilling: null,
  response: null,
  teacherBillingList: [],
  teacherBillingListMeta: {} as ListMeta
};

const slice = createSlice({
  name: 'teacherBilling',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // START LOADING
    reset(state) {
      state.isLoading = false;
      state.error = false;
      state.response = null;
      state.teacherBilling = null;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // CREATE BILLING
    createTeacherBillingSuccess(state, action) {
      state.isLoading = false;
      state.teacherBilling = action.payload.data;
      state.response = {
        data: action.payload.data,
        type: 'CREATE_TEACHER_BILLING_SUCCESS'
      };
    },

    // GET BILLING LIST
    getTeacherBillingSuccess(state, action) {
      state.isLoading = false;
      state.teacherBillingList = action.payload.data;
      state.teacherBillingListMeta = action.payload.meta as ListMeta;
      state.response = {
        data: action.payload.success,
        type: 'GET_TEACHER_BILLING_LIST_SUCCESS'
      };
    },

    // UNDUE BILLING
    undueTeacherBillingSuccess(state, action) {
      state.isLoading = false;
      state.teacherBilling = action.payload.data;
      state.response = {
        data: action.payload.data,
        type: 'UNDUE_TEACHER_BILLING_SUCCESS'
      };
    },

    // RESET RESUBMIT BILLING
    resetResubmitTeacherBillingSuccess(state, action) {
      state.isLoading = false;
      state.teacherBilling = action.payload.data;
      state.response = {
        data: action.payload.data,
        type: 'RESET_RESUBMIT_TEACHER_BILLING_SUCCESS'
      };
    }
  }
});

// Reducer
export default slice.reducer;

// Actions
// ----------------------------------------------------------------------
export function getTeacherBillingBetweenDate(params: any) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/director/billing/get`, { params });
      dispatch(slice.actions.getTeacherBillingSuccess(response.data));
    } catch (error: any) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// for guest

export function getYearTeacherBilling(year: number) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/teacher/billing/get/${year}`);
      dispatch(slice.actions.getTeacherBillingSuccess(response.data));
    } catch (error: any) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getMonthTeacherBilling(year: number, month: number) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/teacher/billing/get/${year}/${month}`);
      dispatch(slice.actions.getTeacherBillingSuccess(response.data));
    } catch (error: any) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function submitTeacherBilling(payload: any) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/teacher/billing/submit', payload);
      dispatch(slice.actions.createTeacherBillingSuccess(response.data));
    } catch (error: any) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function undueTeacherBilling(payload: any) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/teacher/billing/undue', payload);
      dispatch(slice.actions.undueTeacherBillingSuccess(response.data));
    } catch (error: any) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function resetResubmitTeacherBilling(id: number) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(`/teacher/billing/${id}/re-submit/reset`);
      dispatch(slice.actions.resetResubmitTeacherBillingSuccess(response.data));
    } catch (error: any) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function exportTeacherBilling(payload: any) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(`/director-billing/export`, payload, {
        responseType: 'blob'
      });

      // create file link in browser's memory
      const href = URL.createObjectURL(response.data);

      // create "a" HTML element with href to file & click
      const link = document.createElement('a');
      link.href = href;
      link.setAttribute('download', 'TeacherBilling (Export).xlsx'); //or any other extension
      document.body.appendChild(link);
      link.click();

      // clean up "a" element & remove ObjectURL
      document.body.removeChild(link);
      URL.revokeObjectURL(href);
    } catch (error: any) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function resetState() {
  return async () => {
    dispatch(slice.actions.reset());
  };
}
