import { createSlice } from '@reduxjs/toolkit';
import { dispatch } from '../store';
// utils
import axios from '../../utils/axios';
import { Billing } from '../../@types/billing';
import { ListMeta } from '../../@types/table';
// ----------------------------------------------------------------------

type BillingResponse = {
  data: any;
  type: string;
};

type BillingState = {
  isLoading: boolean;
  error: any;
  billing: null | any;
  response: null | BillingResponse;
  billingList: Billing[];
  billingListMeta: ListMeta;
};

const initialState: BillingState = {
  isLoading: false,
  error: false,
  billing: null,
  response: null,
  billingList: [],
  billingListMeta: {} as ListMeta
};

const slice = createSlice({
  name: 'billing',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // START LOADING
    reset(state) {
      state.isLoading = false;
      state.error = false;
      state.response = null;
      state.billing = null;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // CREATE BILLING
    createBillingSuccess(state, action) {
      state.isLoading = false;
      state.billing = action.payload.data;
      state.response = {
        data: action.payload.data,
        type: 'CREATE_BILLING_SUCCESS'
      };
    },

    // GET BILLING LIST
    getBillingSuccess(state, action) {
      state.isLoading = false;
      state.billingList = action.payload.data;
      state.billingListMeta = action.payload.meta as ListMeta;
      state.response = {
        data: action.payload.success,
        type: 'GET_BILLING_LIST_SUCCESS'
      };
    },

    // UNDUE BILLING
    undueBillingSuccess(state, action) {
      state.isLoading = false;
      state.billing = action.payload.data;
      state.response = {
        data: action.payload.data,
        type: 'UNDUE_BILLING_SUCCESS'
      };
    },

    // RESET RESUBMIT BILLING
    resetResubmitBillingSuccess(state, action) {
      state.isLoading = false;
      state.billing = action.payload.data;
      state.response = {
        data: action.payload.data,
        type: 'RESET_RESUBMIT_BILLING_SUCCESS'
      };
    }
  }
});

// Reducer
export default slice.reducer;

// Actions
// ----------------------------------------------------------------------
export function getBillingBetweenDate(params: any) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/billing/get`, { params });
      dispatch(slice.actions.getBillingSuccess(response.data));
    } catch (error: any) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// for guest

export function getYearBilling(year: number) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/provider/billing/get/${year}`);
      dispatch(slice.actions.getBillingSuccess(response.data));
    } catch (error: any) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getMonthBilling(year: number, month: number) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/provider/billing/get/${year}/${month}`);
      dispatch(slice.actions.getBillingSuccess(response.data));
    } catch (error: any) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function submitProviderBilling(payload: any) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/provider/billing/submit', payload);
      dispatch(slice.actions.createBillingSuccess(response.data));
    } catch (error: any) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function undueProviderBilling(payload: any) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/provider/billing/undue', payload);
      dispatch(slice.actions.undueBillingSuccess(response.data));
    } catch (error: any) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function resetResubmitProviderBilling(id: number) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(`/provider/billing/${id}/re-submit/reset`);
      dispatch(slice.actions.resetResubmitBillingSuccess(response.data));
    } catch (error: any) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function exportBilling(payload: any) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(`/billing/export`, payload, {
        responseType: 'blob'
      });

      // create file link in browser's memory
      const href = URL.createObjectURL(response.data);

      // create "a" HTML element with href to file & click
      const link = document.createElement('a');
      link.href = href;
      link.setAttribute('download', 'Billing (Export).xlsx'); //or any other extension
      document.body.appendChild(link);
      link.click();

      // clean up "a" element & remove ObjectURL
      document.body.removeChild(link);
      URL.revokeObjectURL(href);
    } catch (error: any) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function resetState() {
  return async () => {
    dispatch(slice.actions.reset());
  };
}
