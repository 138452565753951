import { createSlice } from '@reduxjs/toolkit';
import { dispatch } from '../store';
// utils
import axios from '../../utils/axios';
import { Bonus } from '../../@types/bonus';
import { ListMeta } from '../../@types/table';
// ----------------------------------------------------------------------

type BonusResponse = {
  data: any;
  type: string;
};

type BonusState = {
  isLoading: boolean;
  error: any;
  bonus: null | any;
  response: null | BonusResponse;
  bonusList: Bonus[];
  bonusListMeta: ListMeta;
};

const initialState: BonusState = {
  isLoading: false,
  error: false,
  bonus: null,
  response: null,
  bonusList: [],
  bonusListMeta: {} as ListMeta
};

const slice = createSlice({
  name: 'bonus',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // START LOADING
    reset(state) {
      state.isLoading = false;
      state.error = false;
      state.response = null;
      state.bonus = null;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // CREATE BONUS
    saveBonusSuccess(state, action) {
      state.isLoading = false;
      state.bonus = action.payload.data;
      state.response = {
        data: action.payload.data,
        type: 'SAVE_BONUS_SUCCESS'
      };
    },

    // GET BONUS LIST
    getBonusSuccess(state, action) {
      state.isLoading = false;
      state.bonus = action.payload.data;
      state.response = {
        data: action.payload.success,
        type: 'GET_BONUS_SUCCESS'
      };
    }
  }
});

// Reducer
export default slice.reducer;

// Actions
// ----------------------------------------------------------------------

export function getBonus() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/bonus/get`);
      dispatch(slice.actions.getBonusSuccess(response.data));
    } catch (error: any) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function saveBonus(payload: any) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/bonus/save', payload);
      dispatch(slice.actions.saveBonusSuccess(response.data));
    } catch (error: any) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// provider

export function getProviderBonus() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`provider/bonus/get`);
      dispatch(slice.actions.getBonusSuccess(response.data));
    } catch (error: any) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function resetState() {
  return async () => {
    dispatch(slice.actions.reset());
  };
}
