import { createSlice } from '@reduxjs/toolkit';
import { dispatch } from '../store';
// utils
import axios from '../../utils/axios';
import { Provider } from '../../@types/provider';
import { ListMeta, ListQueryParams } from '../../@types/table';

// ----------------------------------------------------------------------

type ProviderResponse = {
  data: any;
  type: string;
};

type ProviderState = {
  isLoading: boolean;
  error: any;
  provider: null | Provider;
  response: null | ProviderResponse;
  providerList: Provider[];
  providerListMeta: ListMeta;
};

const initialState: ProviderState = {
  isLoading: false,
  error: false,
  provider: null,
  response: null,
  providerList: [],
  providerListMeta: {} as ListMeta
};

const slice = createSlice({
  name: 'provider',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // START LOADING
    reset(state) {
      state.isLoading = false;
      state.error = false;
      state.response = null;
      state.provider = null;
    },

    // START LOADING
    clearError(state) {
      state.error = false;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;

      state.error = action.payload;
    },

    // GET PROVIDER
    getProviderSuccess(state, action) {
      state.isLoading = false;
      state.provider = action.payload.data;
      state.response = {
        data: action.payload.success,
        type: 'GET_PROVIDER_SUCCESS'
      };
    },

    // CREATE PROVIDER
    createProviderSuccess(state, action) {
      state.isLoading = false;
      state.provider = action.payload.data;
      state.response = {
        data: action.payload.data,
        type: 'CREATE_PROVIDER_SUCCESS'
      };
    },

    // UPDATE PROVIDER
    updateProviderSuccess(state, action) {
      state.isLoading = false;
      state.provider = action.payload.data;
      state.response = {
        data: action.payload.data,
        type: 'UPDATE_PROVIDER_SUCCESS'
      };
    },

    // GET MANAGE PROVIDERS
    getProviderListWithMetaSuccess(state, action) {
      state.isLoading = false;
      state.providerList = action.payload.data;
      state.providerListMeta = action.payload.meta as ListMeta;
      state.response = {
        data: action.payload.data,
        type: 'GET_PROVIDER_LIST_SUCCESS'
      };
    },

    getProviderListSuccess(state, action) {
      state.isLoading = false;
      state.providerList = action.payload.data;
      state.response = {
        data: action.payload.data,
        type: 'GET_PROVIDER_LIST_SUCCESS'
      };
    },

    // GET PROVIDER BY PERSONAL CODE
    getProviderByPersonalCodeSuccess(state, action) {
      state.isLoading = false;
      state.provider = action.payload.data;
      state.response = {
        data: action.payload.success,
        type: 'GET_PROVIDER_BY_PERSONAL_CODE_SUCCESS'
      };
    },

    // SEND PROVIDER TO EMAIL
    sendProviderToEmailSuccess(state, action) {
      state.isLoading = false;
      state.response = {
        data: action.payload,
        type: 'SEND_PROVIDER_TO_EMAIL'
      };
    },

    // UNBLOCK PROVIDER
    unblockProviderSuccess(state, action) {
      state.isLoading = false;
      state.provider = action.payload;
      state.response = {
        data: action.payload.data,
        type: 'UNBLOCK_PROVIDER_SUCCESS'
      };
    },

    // DELETE PROVIDER
    deleteProviderSuccess(state, action) {
      state.isLoading = false;
      state.provider = action.payload;
      state.response = {
        data: action.payload.data,
        type: 'DELETE_PROVIDER_SUCCESS'
      };
    },

    // RESET PASSWORD
    resetProviderPasswordSuccess(state, action) {
      state.isLoading = false;
      state.response = {
        data: action.payload.success,
        type: 'RESET_PROVIDER_PASSWORD_SUCCESS'
      };
    },

    // REQUEST MORE CLIENT
    requestMoreClientSuccess(state, action) {
      state.isLoading = false;
      state.response = {
        data: action.payload.provider,
        type: 'REQUEST_MORE_CLIENT_SUCCESS'
      };
    },

    // GENERATE SHARE LINK
    generateShareLinkSuccess(state, action) {
      state.isLoading = false;
      state.response = {
        data: action.payload.provider,
        type: 'GENERATE_SHARE_LINK_SUCCESS'
      };
    },

    toggleCoAdminProviderSuccess(state, action) {
      state.isLoading = false;
      state.provider = action.payload;
      state.response = {
        data: action.payload.data,
        type: 'TOGGLE_COADMIN_PROVIDER_SUCCESS'
      };
    }
  }
});

// Reducer
export default slice.reducer;

// Actions
// ----------------------------------------------------------------------

export function getProviderById(providerId: string) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`provider/get/${providerId}`);
      dispatch(slice.actions.getProviderSuccess(response.data));
    } catch (error: any) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function createProvider(payload: any) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/provider/create', payload);
      dispatch(slice.actions.createProviderSuccess(response.data));
    } catch (error: any) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function updateProvider(payload: any) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/provider/update', payload);
      dispatch(slice.actions.updateProviderSuccess(response.data));
    } catch (error: any) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function resetProviderPassword(payload: any) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/provider/reset-password', payload);
      dispatch(slice.actions.resetProviderPasswordSuccess(response.data));
    } catch (error: any) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getProviderListPaginated(params: ListQueryParams) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/provider/get/paginate', {
        params: {
          'pagination[pageSize]': params.pageSize,
          'pagination[page]': params.page,
          filters: params.filters
        }
      });
      dispatch(slice.actions.getProviderListWithMetaSuccess(response.data));
      return response;
    } catch (error: any) {
      dispatch(slice.actions.hasError(error));
      return null;
    }
  };
}

export function getProviderListWithClearances(params: ListQueryParams) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/provider/get/all-with-clearances', {
        params: {
          filters: params.filters
        }
      });
      dispatch(slice.actions.getProviderListSuccess(response.data));
      return response;
    } catch (error: any) {
      dispatch(slice.actions.hasError(error));
      return null;
    }
  };
}

export function getProviderList() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/provider/get/all');
      dispatch(slice.actions.getProviderListSuccess(response.data));
      return response;
    } catch (error: any) {
      dispatch(slice.actions.hasError(error));
      return null;
    }
  };
}

export function deleteProvider(providerId: string) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/provider/delete', { providerId });
      dispatch(slice.actions.deleteProviderSuccess(response.data));
    } catch (error: any) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function unblockProvider(providerId: string) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/provider/unblock', { providerId });
      dispatch(slice.actions.unblockProviderSuccess(response.data));
    } catch (error: any) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function toggleIsCoAdminProvider(providerId: string, isCoAdmin: boolean) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.patch(`/provider/${providerId}/co-admin/toggle`, { isCoAdmin });
      dispatch(slice.actions.toggleCoAdminProviderSuccess(response.data));
    } catch (error: any) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// for guest
export function getProviderByPersonalCode(personalCode: string) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/provider/find', {
        personalCode: personalCode
      });
      dispatch(slice.actions.getProviderByPersonalCodeSuccess(response.data));
    } catch (error: any) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// for guest
export function sendProviderToEmail(payload: any) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/provider/send', payload);
      dispatch(slice.actions.sendProviderToEmailSuccess(response.data));
    } catch (error: any) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function requestMoreClients(payload: any) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/provider/request-more-client', payload);
      dispatch(slice.actions.requestMoreClientSuccess(response.data));
    } catch (error: any) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function generateShareLink() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/provider/generate-share-link');
      dispatch(slice.actions.generateShareLinkSuccess(response.data));
    } catch (error: any) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getProviderByShareLinkToken(token: string) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/share-link/${token}`);
      dispatch(slice.actions.getProviderSuccess(response.data));
    } catch (error: any) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getProviderListForProvider() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/provider/providers');
      dispatch(slice.actions.getProviderListSuccess(response.data));
      return response;
    } catch (error: any) {
      dispatch(slice.actions.hasError(error));
      return null;
    }
  };
}

// for guest
export function resetState() {
  return async () => {
    dispatch(slice.actions.reset());
  };
}

// for guest
export function clearError() {
  return async () => {
    dispatch(slice.actions.clearError());
  };
}
