import { createSlice } from '@reduxjs/toolkit';
import { dispatch } from '../store';
// utils
import axios from '../../utils/axios';
import { ServiceStatus } from '../../@types/service-status';
import { ListMeta, ListQueryParams } from '../../@types/table';

// ----------------------------------------------------------------------

type ServiceStatusResponse = {
  data: any;
  type: string;
};

type ServiceStatusState = {
  isLoading: boolean;
  error: any;
  serviceStatus: null | ServiceStatus;
  response: null | ServiceStatusResponse;
  serviceStatusList: ServiceStatus[];
  serviceStatusListMeta: ListMeta;
};

const initialState: ServiceStatusState = {
  isLoading: false,
  error: false,
  serviceStatus: null,
  response: null,
  serviceStatusList: [],
  serviceStatusListMeta: {} as ListMeta
};

const slice = createSlice({
  name: 'serviceStatus',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // START LOADING
    reset(state) {
      state.isLoading = false;
      state.error = false;
      state.response = null;
      state.serviceStatus = null;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET SERVICE STATUS
    getServiceStatusSuccess(state, action) {
      state.isLoading = false;
      state.serviceStatus = action.payload.data;
      state.response = {
        data: action.payload.success,
        type: 'GET_SERVICE_STATUS_SUCCESS'
      };
    },

    // CREATE SERVICE STATUS
    createServiceStatusSuccess(state, action) {
      state.isLoading = false;
      state.serviceStatus = action.payload.data;
      state.response = {
        data: action.payload.data,
        type: 'CREATE_SERVICE_STATUS_SUCCESS'
      };
    },

    // UPDATE SERVICE STATUS
    updateServiceStatusSuccess(state, action) {
      state.isLoading = false;
      state.serviceStatus = action.payload.data;
      state.response = {
        data: action.payload.data,
        type: 'UPDATE_SERVICE_STATUS_SUCCESS'
      };
    },

    // DELETE SERVICE STATUS
    deleteServiceStatusSuccess(state, action) {
      state.isLoading = false;
      state.serviceStatus = action.payload;
      state.response = {
        data: action.payload.data,
        type: 'DELETE_SERVICE_STATUS_SUCCESS'
      };
    },

    // GET MANAGE SERVICE STATUS
    getServiceStatusListSuccess(state, action) {
      state.isLoading = false;
      state.serviceStatusList = action.payload.data;
      state.serviceStatusListMeta = action.payload.meta as ListMeta;
      state.response = {
        data: action.payload.data,
        type: 'GET_SERVICE_STATUS_LIST_SUCCESS'
      };
    },

    // GET MANAGE SERVICE STATUS
    getAllServiceStatusListSuccess(state, action) {
      state.isLoading = false;
      state.serviceStatusList = action.payload.data;
      state.response = {
        data: action.payload.data,
        type: 'GET_SERVICE_STATUS_LIST_SUCCESS'
      };
    }
  }
});

// Reducer
export default slice.reducer;

// Actions
// ----------------------------------------------------------------------

export function getServiceStatusById(serviceStatusId: string) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`service-status/get/${serviceStatusId}`);
      dispatch(slice.actions.getServiceStatusSuccess(response.data));
    } catch (error: any) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function createServiceStatus(payload: any) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/service-status/create', payload);
      dispatch(slice.actions.createServiceStatusSuccess(response.data));
    } catch (error: any) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function updateServiceStatus(payload: any) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/service-status/update', payload);
      dispatch(slice.actions.updateServiceStatusSuccess(response.data));
    } catch (error: any) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getServiceStatusList(params: ListQueryParams) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/service-status/get/paginate', {
        params: {
          'pagination[pageSize]': params.pageSize,
          'pagination[page]': params.page
        }
      });
      dispatch(slice.actions.getServiceStatusListSuccess(response.data));
    } catch (error: any) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getAllServiceStatusList() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/service-status/get/all');
      dispatch(slice.actions.getAllServiceStatusListSuccess(response.data));
    } catch (error: any) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function deleteServiceStatusById(serviceStatusId: string) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/service-status/delete', { serviceStatusId });
      dispatch(slice.actions.deleteServiceStatusSuccess(response.data));
    } catch (error: any) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// for guest
export function getServiceStatusListForGuest() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/provider/service-status/get/all');
      dispatch(slice.actions.getAllServiceStatusListSuccess(response.data));
    } catch (error: any) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// for guest
export function resetState() {
  return async () => {
    dispatch(slice.actions.reset());
  };
}
