import * as Yup from 'yup';
import { useSnackbar } from 'notistack';
import React, { useState, useEffect } from 'react';
import { Icon } from '@iconify/react';
import closeFill from '@iconify/icons-eva/close-fill';
// material
import {
  Box,
  Backdrop,
  Paper,
  Typography,
  Stack,
  TextField,
  Alert,
  Button,
  FormHelperText,
  Switch,
  Divider
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
// redux
import { RootState, useDispatch, useSelector } from '../../../redux/store';
// hooks
import useIsMountedRef from '../../../hooks/useIsMountedRef';
//
import Scrollbar from '../../Scrollbar';
import { MIconButton } from '../../@material-extend';
import { Form, FormikProvider, useFormik, FieldArray, ErrorMessage } from 'formik';
import { updateGeneralSetting, getGeneralSetting } from 'redux/slices/agency';
import {
  createDirectorDashboard,
  getDirectorDashboard,
  getDirectorSetting,
  resetState
} from 'redux/slices/director-dashboard';
import { result } from 'lodash';
// ----------------------------------------------------------------------

const DRAWER_WIDTH = 360;

type InitialValues = {
  emails: Array<string>;
};

type GeneralSettingsFormProps = {
  openForm: boolean;
  formClosed: VoidFunction;
};

export default function GeneralDirectorSettingForm({
  openForm,
  formClosed
}: GeneralSettingsFormProps) {
  const dispatch = useDispatch();
  const isMountedRef = useIsMountedRef();
  const [open, setOpen] = useState(false);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const { error: agencyError, response: agencyResponse } = useSelector(
    (state: RootState) => state.agency
  );
  const [alertError, setAlertError] = useState('');
  const daysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
  const [showToast, setShowToast] = useState(false);
  const [isFormOpen, setIsFormOpen] = useState(true);
  const { error: DirectorError, response: DirectorResponse } = useSelector(
    (state: RootState) => state.directorDashboard
  );
  const [state, setState] = useState(
    daysOfWeek.reduce((acc, day) => {
      acc[day.toLowerCase()] = false;
      return acc;
    }, {} as Record<string, boolean>)
  );

  const handleChange = (event: any) => {
    const { name, checked } = event.target;
    setState((prevState) => ({
      ...prevState,
      [name]: checked
    }));
  };

  // useEffect(() => {
  //   dispatch(getDirectorSetting());

  //   const result = dispatch(getDirectorSetting());
  //   console.log(result);
  // });
  // useEffect(() => {
  //   const fetchData = async () => {
  //     const result = await dispatch(getDirectorSetting());
  //     console.log(result.data[0].sunday);
  //     if (result.data) {
  //       setState({
  //         sunday: result.data.sunday || false,
  //         monday: result.data.monday || false,
  //         tuesday: result.data.tuesday || false,
  //         wednesday: result.data.wednesday || false,
  //         thursday: result.data.thursday || false,
  //         friday: result.data.friday || false,
  //         saturday: result.data.saturday || false
  //       });
  //     }
  //   };

  //   console.log(setState);
  //   fetchData();
  // }, [dispatch]);
  useEffect(() => {
    console.log(DirectorError);
    if (DirectorError) {
      setAlertError(DirectorError.message);
    }
  }, [DirectorError]);

  // console.log('Director', DirectorResponse);
  useEffect(() => {
    const fetchData = async () => {
      const result = await dispatch(getDirectorSetting());

      if (result.data && result.data.length > 0) {
        const settings = result.data[0];

        setState({
          sunday: settings.sunday || false,
          monday: settings.monday || false,
          tuesday: settings.tuesday || false,
          wednesday: settings.wednesday || false,
          thursday: settings.thursday || false,
          friday: settings.friday || false,
          saturday: settings.saturday || false
        });
      }
    };

    fetchData();
  }, []);
  useEffect(() => {
    if (DirectorResponse && DirectorResponse.type === 'CREATE_DIRECTOR_SUCCESSS') {
      enqueueSnackbar('Create success', {
        variant: 'success',
        action: (key) => (
          <MIconButton size="small" onClick={() => closeSnackbar(key)}>
            <Icon icon={closeFill} />
          </MIconButton>
        )
      });

      handleClose();
      dispatch(resetState());
    }
  }, [DirectorResponse]);

  const handleSubmitRecord = () => {
    const payload = {
      sunday: state.sunday,
      monday: state.monday,
      tuesday: state.tuesday,
      wednesday: state.wednesday,
      thursday: state.thursday,
      friday: state.friday,
      saturday: state.saturday
    };

    dispatch(createDirectorDashboard(payload));
  };

  useEffect(() => {
    setOpen(openForm);
  }, [openForm]);

  const handleClose = () => {
    setOpen(false);
    formik.resetForm();
    setAlertError('');
    formClosed();
  };

  const GeneralSettingsSchema = Yup.object().shape({
    emails: Yup.array().min(1, 'Must have 1 email').required('Required')
  });

  const formik = useFormik<InitialValues>({
    initialValues: {
      emails: []
    },
    validationSchema: GeneralSettingsSchema,
    onSubmit: async (values, { setSubmitting, resetForm, setErrors }) => {
      // try {
      //   const payload = {
      //     sunday: state.sunday,
      //     monday: state.monday,
      //     tuesday: state.tuesday,
      //     wednesday: state.wednesday,
      //     thursday: state.thursday,
      //     friday: state.friday,
      //     saturday: state.saturday
      //   };
      //   dispatch(createDirectorDashboard(payload));
      // } catch (error: any) {
      //   console.error(error);
      //   setSubmitting(false);
      //   setErrors(error);
      // }
      // console.log(onsubmit);
    }
  });

  const { handleSubmit, isSubmitting, getFieldProps, values, setFieldValue } = formik;

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Backdrop
          sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={open}
          onClick={handleClose}
        />

        <Box
          sx={[
            {
              top: 0,
              bottom: 0,
              right: 0,
              position: 'fixed',
              zIndex: 2001,
              ...(open && { right: 0 })
            },
            (theme) => ({
              [theme.breakpoints.down('sm')]: {
                top: '70px'
              }
            })
          ]}
        >
          <Paper
            sx={{
              height: 1,
              width: '0px',
              borderRadius: 0,
              overflow: 'hidden',
              boxShadow: (theme) => theme.customShadows.z24,
              transition: (theme) => theme.transitions.create('width'),
              ...(open && { width: DRAWER_WIDTH })
            }}
          >
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              sx={{ py: 2, pr: 1, pl: 2.5 }}
            >
              <Typography variant="subtitle1">Director Settings</Typography>
              <MIconButton onClick={handleClose}>
                <Icon icon={closeFill} width={20} height={20} />
              </MIconButton>
            </Stack>

            <Scrollbar sx={{ height: 1 }}>
              {alertError && <Alert severity="error">{alertError}</Alert>}
              {daysOfWeek.map((day) => (
                <Stack key={day}>
                  <Stack direction="row" justifyContent="space-between">
                    <Typography sx={{ marginLeft: 3, marginTop: 1 }}>{day}</Typography>
                    <Switch
                      checked={state[day.toLowerCase()]}
                      onChange={handleChange}
                      name={day.toLowerCase()}
                    />
                  </Stack>
                  <Divider orientation="horizontal" sx={{ marginTop: 2 }}></Divider>
                </Stack>
              ))}

              <Button
                variant="contained"
                sx={{ marginLeft: 2, marginRight: 2, marginTop: 3, width: 300, height: 50 }}
                onClick={handleSubmitRecord}
              >
                Save
              </Button>
              {/* <Stack spacing={1.5}>
                <LoadingButton
                  fullWidth
                  size="large"
                  type="submit"
                  variant="contained"
                  loading={isSubmitting}
                >
                  {'Save'}
                </LoadingButton>
              </Stack> */}
            </Scrollbar>
          </Paper>
        </Box>
      </Form>
    </FormikProvider>
  );
}
