import { createSlice } from '@reduxjs/toolkit';
import { dispatch } from '../store';
// utils
import axios from '../../utils/axios';
import { ProviderRate } from '../../@types/provider';
import { ListMeta, ListQueryParams } from '../../@types/table';

// ----------------------------------------------------------------------

type ProviderRateResponse = {
  data: any;
  type: string;
};

type ProviderRateState = {
  isLoading: boolean;
  error: any;
  providerRate: null | ProviderRate;
  response: null | ProviderRateResponse;
  providerRateList: ProviderRate[];
  providerRateListMeta: ListMeta;
};

const initialState: ProviderRateState = {
  isLoading: false,
  error: false,
  providerRate: null,
  response: null,
  providerRateList: [],
  providerRateListMeta: {} as ListMeta
};

const slice = createSlice({
  name: 'providerRate',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // START LOADING
    reset(state) {
      state.isLoading = false;
      state.error = false;
      state.response = null;
      state.providerRate = null;
    },

    // START LOADING
    clearError(state) {
      state.error = false;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;

      state.error = action.payload;
    },

    // DELETE PROVIDER
    deleteProviderRateSuccess(state, action) {
      state.isLoading = false;
      state.providerRate = action.payload;
      state.response = {
        data: action.payload.data,
        type: 'DELETE_PROVIDER_RATE_SUCCESS'
      };
    },

    updateProviderRateSuccess(state, action) {
      state.isLoading = false;
      state.providerRate = action.payload.data;
      state.response = {
        data: action.payload.data,
        type: 'UPDATE_PROVIDER_RATE_SUCCESS'
      };
    },

    getProviderRateListSuccess(state, action) {
      state.isLoading = false;
      state.providerRateList = action.payload.data;
      state.providerRateListMeta = action.payload.meta as ListMeta;
      state.response = {
        data: action.payload.data,
        type: 'GET_PROVIDER_RATE_LIST_SUCCESS'
      };
    },

    getProviderRateSuccess(state, action) {
      state.isLoading = false;
      state.providerRate = action.payload.data;
      state.response = {
        data: action.payload.success,
        type: 'GET_PROVIDER_RATE_SUCCESS'
      };
    }
  }
});

// Reducer
export default slice.reducer;

// Actions
// ----------------------------------------------------------------------

export function getProviderBillingById(providerId: string, rateId: string) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      if (rateId === 'new') {
        let tmpData = {
          data: {
            id: '0',
            startDate: '',
            endDate: '',
            rate: '',
            profit: '',
            providerId: providerId
          }
        };
        dispatch(slice.actions.getProviderRateSuccess(tmpData));
      } else {
        const response = await axios.get(`provider/${providerId}/rate/${rateId}/get`);
        dispatch(slice.actions.getProviderRateSuccess(response.data));
      }
    } catch (error: any) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getProviderRateList(params: ListQueryParams) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/provider/get/rate/all', {
        params: {
          'pagination[pageSize]': params.pageSize,
          'pagination[page]': params.page,
          filters: params.filters
        }
      });
      dispatch(slice.actions.getProviderRateListSuccess(response.data));
      return response;
    } catch (error: any) {
      dispatch(slice.actions.hasError(error));
      return null;
    }
  };
}

export function deleteProviderRateById(providerId: string, rateId: string) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.delete(`/provider/${providerId}/rate/${rateId}/delete`);
      dispatch(slice.actions.deleteProviderRateSuccess(response.data));
    } catch (error: any) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function updateProviderRate(payload: any) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/provider/rate/update', payload);
      dispatch(slice.actions.updateProviderRateSuccess(response.data));
    } catch (error: any) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getAllProviderRate() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/providers/rate/latest');
      dispatch(slice.actions.getProviderRateListSuccess(response.data));
      return response;
    } catch (error: any) {
      dispatch(slice.actions.hasError(error));
      return null;
    }
  };
}

// for guest
export function getProviderRate() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/provider/rate/latest');
      dispatch(slice.actions.getProviderRateSuccess(response.data));
      return response;
    } catch (error: any) {
      dispatch(slice.actions.hasError(error));
      return null;
    }
  };
}

export function resetState() {
  return async () => {
    dispatch(slice.actions.reset());
  };
}

// for guest
export function clearError() {
  return async () => {
    dispatch(slice.actions.clearError());
  };
}
