import { createSlice } from '@reduxjs/toolkit';
import { dispatch } from '../store';
// utils
import axios from '../../utils/axios';
import { TeacherRate } from '../../@types/teacher';
import { ListMeta, ListQueryParams } from '../../@types/table';

// ----------------------------------------------------------------------

type TeacherRateResponse = {
  data: any;
  type: string;
};

type TeacherRateState = {
  isLoading: boolean;
  error: any;
  teacherRate: null | TeacherRate;
  response: null | TeacherRateResponse;
  teacherRateList: TeacherRate[];
  teacherRateListMeta: ListMeta;
};

const initialState: TeacherRateState = {
  isLoading: false,
  error: false,
  teacherRate: null,
  response: null,
  teacherRateList: [],
  teacherRateListMeta: {} as ListMeta
};

const slice = createSlice({
  name: 'teacherRate',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // START LOADING
    reset(state) {
      state.isLoading = false;
      state.error = false;
      state.response = null;
      state.teacherRate = null;
    },

    // START LOADING
    clearError(state) {
      state.error = false;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;

      state.error = action.payload;
    },

    // DELETE
    deleteTeacherRateSuccess(state, action) {
      state.isLoading = false;
      state.teacherRate = action.payload;
      state.response = {
        data: action.payload.data,
        type: 'DELETE_TEACHER_RATE_SUCCESS'
      };
    },

    updateTeacherRateSuccess(state, action) {
      state.isLoading = false;
      state.teacherRate = action.payload.data;
      state.response = {
        data: action.payload.data,
        type: 'UPDATE_TEACHER_RATE_SUCCESS'
      };
    },

    getTeacherRateListSuccess(state, action) {
      state.isLoading = false;
      state.teacherRateList = action.payload.data;
      state.teacherRateListMeta = action.payload.meta as ListMeta;
      state.response = {
        data: action.payload.data,
        type: 'GET_TEACHER_RATE_LIST_SUCCESS'
      };
    },

    getTeacherRateSuccess(state, action) {
      state.isLoading = false;
      state.teacherRate = action.payload.data;
      state.response = {
        data: action.payload.success,
        type: 'GET_TEACHER_RATE_SUCCESS'
      };
    }
  }
});

// Reducer
export default slice.reducer;

// Actions
// ----------------------------------------------------------------------

export function getTeacherBillingById(teacherId: string, rateId: string) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      if (rateId === 'new') {
        let tmpData = {
          data: {
            id: '0',
            startDate: '',
            endDate: '',
            rate: '',
            profit: '',
            teacherId: teacherId
          }
        };
        dispatch(slice.actions.getTeacherRateSuccess(tmpData));
      } else {
        const response = await axios.get(`teacher/${teacherId}/rate/${rateId}/get`);
        dispatch(slice.actions.getTeacherRateSuccess(response.data));
      }
    } catch (error: any) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getTeacherRateList(params: ListQueryParams) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/teacher/get/rate/all', {
        params: {
          'pagination[pageSize]': params.pageSize,
          'pagination[page]': params.page,
          filters: params.filters
        }
      });
      dispatch(slice.actions.getTeacherRateListSuccess(response.data));
      return response;
    } catch (error: any) {
      dispatch(slice.actions.hasError(error));
      return null;
    }
  };
}

export function deleteTeacherRateById(teacherId: string, rateId: string) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.delete(`/teacher/${teacherId}/rate/${rateId}/delete`);
      dispatch(slice.actions.deleteTeacherRateSuccess(response.data));
    } catch (error: any) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function updateTeacherRate(payload: any) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/teacher/rate/update', payload);
      dispatch(slice.actions.updateTeacherRateSuccess(response.data));
    } catch (error: any) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getAllTeacherRate() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/teachers/rate/latest');
      dispatch(slice.actions.getTeacherRateListSuccess(response.data));
      return response;
    } catch (error: any) {
      dispatch(slice.actions.hasError(error));
      return null;
    }
  };
}

// for guest
export function getTeacherRate() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/teacher/rate/latest');
      dispatch(slice.actions.getTeacherRateSuccess(response.data));
      return response;
    } catch (error: any) {
      dispatch(slice.actions.hasError(error));
      return null;
    }
  };
}

export function resetState() {
  return async () => {
    dispatch(slice.actions.reset());
  };
}

// for guest
export function clearError() {
  return async () => {
    dispatch(slice.actions.clearError());
  };
}
