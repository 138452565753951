import { createSlice } from '@reduxjs/toolkit';
import { dispatch } from '../store';
// utils
import axios from '../../utils/axios';
import { DirectorBilling } from '../../@types/director-billing';
import { ListMeta } from '../../@types/table';
// ----------------------------------------------------------------------

type DirectorBillingResponse = {
  data: any;
  type: string;
};

type DirectorBillingState = {
  isLoading: boolean;
  error: any;
  directorBilling: null | any;
  response: null | DirectorBillingResponse;
  directorBillingList: DirectorBilling[];
  directorBillingListMeta: ListMeta;
};

const initialState: DirectorBillingState = {
  isLoading: false,
  error: false,
  directorBilling: null,
  response: null,
  directorBillingList: [],
  directorBillingListMeta: {} as ListMeta
};

const slice = createSlice({
  name: 'directorBilling',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // START LOADING
    reset(state) {
      state.isLoading = false;
      state.error = false;
      state.response = null;
      state.directorBilling = null;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // CREATE DIRECTOR BILLING
    createDirectorBillingSuccess(state, action) {
      state.isLoading = false;
      state.directorBilling = action.payload.data;
      state.response = {
        data: action.payload.data,
        type: 'CREATE_DIRECTOR_BILLING_SUCCESS'
      };
    },

    // UNDO DIRECTOR BILLING
    undoDirectorBillingSuccess(state, action) {
      state.isLoading = false;
      state.directorBilling = action.payload.data;
      state.response = {
        data: action.payload.data,
        type: 'UNDO_DIRECTOR_BILLING_SUCCESS'
      };
    },

    // GET DIRECTOR BILLING LIST
    getDirectorBillingSuccess(state, action) {
      state.isLoading = false;
      state.directorBillingList = action.payload.data;
      state.directorBillingListMeta = action.payload.meta as ListMeta;
      state.response = {
        data: action.payload.success,
        type: 'GET_DIRECTOR_BILLING_LIST_SUCCESS'
      };
    }
  }
});

// Reducer
export default slice.reducer;

// Actions
// ----------------------------------------------------------------------
export function getDirectorBillingBetweenDate(params: any) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/director-billing/get`, { params });
      dispatch(slice.actions.getDirectorBillingSuccess(response.data));
    } catch (error: any) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function submitDirectorBilling(payload: any) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/director-billing/submit', payload);
      dispatch(slice.actions.createDirectorBillingSuccess(response.data));
    } catch (error: any) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function undoSubmitDirectorBilling(payload: any) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/director-billing/submit/undo', payload);
      dispatch(slice.actions.undoDirectorBillingSuccess(response.data));
    } catch (error: any) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getMonthDirectorBilling(year: number, month: number) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/teacher/director-billing/get/${year}/${month}`);
      dispatch(slice.actions.getDirectorBillingSuccess(response.data));
    } catch (error: any) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function resetState() {
  return async () => {
    dispatch(slice.actions.reset());
  };
}
